.fireflies_firefly__1W6BJ {
    position: fixed;
    left: 50%;
    top: 50%;
    width: .4vw;
    height: .4vw;
    margin: -.2vw 0 0 9.8vw;
    -webkit-animation: ease 200s alternate infinite;
            animation: ease 200s alternate infinite;
    pointer-events: none
}

.fireflies_firefly__1W6BJ::after,
.fireflies_firefly__1W6BJ::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-transform-origin: -10vw;
            transform-origin: -10vw
}

.fireflies_firefly__1W6BJ::before {
    background: #000;
    opacity: .4;
    -webkit-animation: fireflies_drift__1gY9T ease alternate infinite;
            animation: fireflies_drift__1gY9T ease alternate infinite
}

.fireflies_firefly__1W6BJ::after {
    background: #fff;
    opacity: 0;
    box-shadow: 0 0 0 0 #ff0;
    -webkit-animation: fireflies_drift__1gY9T ease alternate infinite, fireflies_flash__1tDgO ease infinite;
            animation: fireflies_drift__1gY9T ease alternate infinite, fireflies_flash__1tDgO ease infinite
}

.fireflies_firefly__1W6BJ:nth-child(1) {
    -webkit-animation-name: fireflies_move1__XZTtG;
            animation-name: fireflies_move1__XZTtG
}

.fireflies_firefly__1W6BJ:nth-child(1)::before {
    -webkit-animation-duration: 13s;
            animation-duration: 13s
}

.fireflies_firefly__1W6BJ:nth-child(1)::after {
    -webkit-animation-duration: 13s, 7585ms;
            animation-duration: 13s, 7585ms;
    -webkit-animation-delay: 0s, 7784ms;
            animation-delay: 0s, 7784ms
}

@-webkit-keyframes fireflies_move1__XZTtG {
    0% {
        -webkit-transform: translateX(-49vw) translateY(2vh) scale(.74);
                transform: translateX(-49vw) translateY(2vh) scale(.74)
    }

    3.5714285714% {
        -webkit-transform: translateX(-40vw) translateY(19vh) scale(.93);
                transform: translateX(-40vw) translateY(19vh) scale(.93)
    }

    7.1428571429% {
        -webkit-transform: translateX(-18vw) translateY(-17vh) scale(.61);
                transform: translateX(-18vw) translateY(-17vh) scale(.61)
    }

    10.7142857143% {
        -webkit-transform: translateX(-10vw) translateY(-23vh) scale(.35);
                transform: translateX(-10vw) translateY(-23vh) scale(.35)
    }

    14.2857142857% {
        -webkit-transform: translateX(37vw) translateY(-28vh) scale(.37);
                transform: translateX(37vw) translateY(-28vh) scale(.37)
    }

    17.8571428571% {
        -webkit-transform: translateX(-37vw) translateY(38vh) scale(.41);
                transform: translateX(-37vw) translateY(38vh) scale(.41)
    }

    21.4285714286% {
        -webkit-transform: translateX(49vw) translateY(5vh) scale(.5);
                transform: translateX(49vw) translateY(5vh) scale(.5)
    }

    25% {
        -webkit-transform: translateX(14vw) translateY(-23vh) scale(.8);
                transform: translateX(14vw) translateY(-23vh) scale(.8)
    }

    28.5714285714% {
        -webkit-transform: translateX(31vw) translateY(49vh) scale(.9);
                transform: translateX(31vw) translateY(49vh) scale(.9)
    }

    32.1428571429% {
        -webkit-transform: translateX(30vw) translateY(-12vh) scale(.64);
                transform: translateX(30vw) translateY(-12vh) scale(.64)
    }

    35.7142857143% {
        -webkit-transform: translateX(-27vw) translateY(31vh) scale(.4);
                transform: translateX(-27vw) translateY(31vh) scale(.4)
    }

    39.2857142857% {
        -webkit-transform: translateX(38vw) translateY(-37vh) scale(.88);
                transform: translateX(38vw) translateY(-37vh) scale(.88)
    }

    42.8571428571% {
        -webkit-transform: translateX(-9vw) translateY(23vh) scale(.64);
                transform: translateX(-9vw) translateY(23vh) scale(.64)
    }

    46.4285714286% {
        -webkit-transform: translateX(21vw) translateY(32vh) scale(.85);
                transform: translateX(21vw) translateY(32vh) scale(.85)
    }

    50% {
        -webkit-transform: translateX(-28vw) translateY(35vh) scale(.27);
                transform: translateX(-28vw) translateY(35vh) scale(.27)
    }

    53.5714285714% {
        -webkit-transform: translateX(22vw) translateY(-24vh) scale(1);
                transform: translateX(22vw) translateY(-24vh) scale(1)
    }

    57.1428571429% {
        -webkit-transform: translateX(26vw) translateY(-29vh) scale(.89);
                transform: translateX(26vw) translateY(-29vh) scale(.89)
    }

    60.7142857143% {
        -webkit-transform: translateX(-2vw) translateY(-43vh) scale(.37);
                transform: translateX(-2vw) translateY(-43vh) scale(.37)
    }

    64.2857142857% {
        -webkit-transform: translateX(-24vw) translateY(-28vh) scale(.56);
                transform: translateX(-24vw) translateY(-28vh) scale(.56)
    }

    67.8571428571% {
        -webkit-transform: translateX(39vw) translateY(-25vh) scale(.6);
                transform: translateX(39vw) translateY(-25vh) scale(.6)
    }

    71.4285714286% {
        -webkit-transform: translateX(34vw) translateY(20vh) scale(.71);
                transform: translateX(34vw) translateY(20vh) scale(.71)
    }

    75% {
        -webkit-transform: translateX(25vw) translateY(29vh) scale(.64);
                transform: translateX(25vw) translateY(29vh) scale(.64)
    }

    78.5714285714% {
        -webkit-transform: translateX(22vw) translateY(14vh) scale(.65);
                transform: translateX(22vw) translateY(14vh) scale(.65)
    }

    82.1428571429% {
        -webkit-transform: translateX(18vw) translateY(13vh) scale(.51);
                transform: translateX(18vw) translateY(13vh) scale(.51)
    }

    85.7142857143% {
        -webkit-transform: translateX(-12vw) translateY(43vh) scale(.84);
                transform: translateX(-12vw) translateY(43vh) scale(.84)
    }

    89.2857142857% {
        -webkit-transform: translateX(5vw) translateY(-26vh) scale(.61);
                transform: translateX(5vw) translateY(-26vh) scale(.61)
    }

    92.8571428571% {
        -webkit-transform: translateX(-23vw) translateY(50vh) scale(.36);
                transform: translateX(-23vw) translateY(50vh) scale(.36)
    }

    96.4285714286% {
        -webkit-transform: translateX(6vw) translateY(-47vh) scale(.4);
                transform: translateX(6vw) translateY(-47vh) scale(.4)
    }

    100% {
        -webkit-transform: translateX(13vw) translateY(-32vh) scale(.66);
                transform: translateX(13vw) translateY(-32vh) scale(.66)
    }
}

@keyframes fireflies_move1__XZTtG {
    0% {
        -webkit-transform: translateX(-49vw) translateY(2vh) scale(.74);
                transform: translateX(-49vw) translateY(2vh) scale(.74)
    }

    3.5714285714% {
        -webkit-transform: translateX(-40vw) translateY(19vh) scale(.93);
                transform: translateX(-40vw) translateY(19vh) scale(.93)
    }

    7.1428571429% {
        -webkit-transform: translateX(-18vw) translateY(-17vh) scale(.61);
                transform: translateX(-18vw) translateY(-17vh) scale(.61)
    }

    10.7142857143% {
        -webkit-transform: translateX(-10vw) translateY(-23vh) scale(.35);
                transform: translateX(-10vw) translateY(-23vh) scale(.35)
    }

    14.2857142857% {
        -webkit-transform: translateX(37vw) translateY(-28vh) scale(.37);
                transform: translateX(37vw) translateY(-28vh) scale(.37)
    }

    17.8571428571% {
        -webkit-transform: translateX(-37vw) translateY(38vh) scale(.41);
                transform: translateX(-37vw) translateY(38vh) scale(.41)
    }

    21.4285714286% {
        -webkit-transform: translateX(49vw) translateY(5vh) scale(.5);
                transform: translateX(49vw) translateY(5vh) scale(.5)
    }

    25% {
        -webkit-transform: translateX(14vw) translateY(-23vh) scale(.8);
                transform: translateX(14vw) translateY(-23vh) scale(.8)
    }

    28.5714285714% {
        -webkit-transform: translateX(31vw) translateY(49vh) scale(.9);
                transform: translateX(31vw) translateY(49vh) scale(.9)
    }

    32.1428571429% {
        -webkit-transform: translateX(30vw) translateY(-12vh) scale(.64);
                transform: translateX(30vw) translateY(-12vh) scale(.64)
    }

    35.7142857143% {
        -webkit-transform: translateX(-27vw) translateY(31vh) scale(.4);
                transform: translateX(-27vw) translateY(31vh) scale(.4)
    }

    39.2857142857% {
        -webkit-transform: translateX(38vw) translateY(-37vh) scale(.88);
                transform: translateX(38vw) translateY(-37vh) scale(.88)
    }

    42.8571428571% {
        -webkit-transform: translateX(-9vw) translateY(23vh) scale(.64);
                transform: translateX(-9vw) translateY(23vh) scale(.64)
    }

    46.4285714286% {
        -webkit-transform: translateX(21vw) translateY(32vh) scale(.85);
                transform: translateX(21vw) translateY(32vh) scale(.85)
    }

    50% {
        -webkit-transform: translateX(-28vw) translateY(35vh) scale(.27);
                transform: translateX(-28vw) translateY(35vh) scale(.27)
    }

    53.5714285714% {
        -webkit-transform: translateX(22vw) translateY(-24vh) scale(1);
                transform: translateX(22vw) translateY(-24vh) scale(1)
    }

    57.1428571429% {
        -webkit-transform: translateX(26vw) translateY(-29vh) scale(.89);
                transform: translateX(26vw) translateY(-29vh) scale(.89)
    }

    60.7142857143% {
        -webkit-transform: translateX(-2vw) translateY(-43vh) scale(.37);
                transform: translateX(-2vw) translateY(-43vh) scale(.37)
    }

    64.2857142857% {
        -webkit-transform: translateX(-24vw) translateY(-28vh) scale(.56);
                transform: translateX(-24vw) translateY(-28vh) scale(.56)
    }

    67.8571428571% {
        -webkit-transform: translateX(39vw) translateY(-25vh) scale(.6);
                transform: translateX(39vw) translateY(-25vh) scale(.6)
    }

    71.4285714286% {
        -webkit-transform: translateX(34vw) translateY(20vh) scale(.71);
                transform: translateX(34vw) translateY(20vh) scale(.71)
    }

    75% {
        -webkit-transform: translateX(25vw) translateY(29vh) scale(.64);
                transform: translateX(25vw) translateY(29vh) scale(.64)
    }

    78.5714285714% {
        -webkit-transform: translateX(22vw) translateY(14vh) scale(.65);
                transform: translateX(22vw) translateY(14vh) scale(.65)
    }

    82.1428571429% {
        -webkit-transform: translateX(18vw) translateY(13vh) scale(.51);
                transform: translateX(18vw) translateY(13vh) scale(.51)
    }

    85.7142857143% {
        -webkit-transform: translateX(-12vw) translateY(43vh) scale(.84);
                transform: translateX(-12vw) translateY(43vh) scale(.84)
    }

    89.2857142857% {
        -webkit-transform: translateX(5vw) translateY(-26vh) scale(.61);
                transform: translateX(5vw) translateY(-26vh) scale(.61)
    }

    92.8571428571% {
        -webkit-transform: translateX(-23vw) translateY(50vh) scale(.36);
                transform: translateX(-23vw) translateY(50vh) scale(.36)
    }

    96.4285714286% {
        -webkit-transform: translateX(6vw) translateY(-47vh) scale(.4);
                transform: translateX(6vw) translateY(-47vh) scale(.4)
    }

    100% {
        -webkit-transform: translateX(13vw) translateY(-32vh) scale(.66);
                transform: translateX(13vw) translateY(-32vh) scale(.66)
    }
}

.fireflies_firefly__1W6BJ:nth-child(2) {
    -webkit-animation-name: fireflies_move2__1cvqI;
            animation-name: fireflies_move2__1cvqI
}

.fireflies_firefly__1W6BJ:nth-child(2)::before {
    -webkit-animation-duration: 14s;
            animation-duration: 14s
}

.fireflies_firefly__1W6BJ:nth-child(2)::after {
    -webkit-animation-duration: 14s, 5529ms;
            animation-duration: 14s, 5529ms;
    -webkit-animation-delay: 0s, 2903ms;
            animation-delay: 0s, 2903ms
}

@-webkit-keyframes fireflies_move2__1cvqI {
    0% {
        -webkit-transform: translateX(-6vw) translateY(49vh) scale(.47);
                transform: translateX(-6vw) translateY(49vh) scale(.47)
    }

    4.347826087% {
        -webkit-transform: translateX(-49vw) translateY(30vh) scale(.97);
                transform: translateX(-49vw) translateY(30vh) scale(.97)
    }

    8.6956521739% {
        -webkit-transform: translateX(-8vw) translateY(34vh) scale(.6);
                transform: translateX(-8vw) translateY(34vh) scale(.6)
    }

    13.0434782609% {
        -webkit-transform: translateX(21vw) translateY(-38vh) scale(.78);
                transform: translateX(21vw) translateY(-38vh) scale(.78)
    }

    17.3913043478% {
        -webkit-transform: translateX(-49vw) translateY(34vh) scale(.74);
                transform: translateX(-49vw) translateY(34vh) scale(.74)
    }

    21.7391304348% {
        -webkit-transform: translateX(48vw) translateY(10vh) scale(.7);
                transform: translateX(48vw) translateY(10vh) scale(.7)
    }

    26.0869565217% {
        -webkit-transform: translateX(9vw) translateY(40vh) scale(.3);
                transform: translateX(9vw) translateY(40vh) scale(.3)
    }

    30.4347826087% {
        -webkit-transform: translateX(36vw) translateY(-42vh) scale(.85);
                transform: translateX(36vw) translateY(-42vh) scale(.85)
    }

    34.7826086957% {
        -webkit-transform: translateX(26vw) translateY(-17vh) scale(.77);
                transform: translateX(26vw) translateY(-17vh) scale(.77)
    }

    39.1304347826% {
        -webkit-transform: translateX(-8vw) translateY(35vh) scale(.56);
                transform: translateX(-8vw) translateY(35vh) scale(.56)
    }

    43.4782608696% {
        -webkit-transform: translateX(22vw) translateY(20vh) scale(.43);
                transform: translateX(22vw) translateY(20vh) scale(.43)
    }

    47.8260869565% {
        -webkit-transform: translateX(-15vw) translateY(31vh) scale(.52);
                transform: translateX(-15vw) translateY(31vh) scale(.52)
    }

    52.1739130435% {
        -webkit-transform: translateX(19vw) translateY(-46vh) scale(.86);
                transform: translateX(19vw) translateY(-46vh) scale(.86)
    }

    56.5217391304% {
        -webkit-transform: translateX(-45vw) translateY(-28vh) scale(.86);
                transform: translateX(-45vw) translateY(-28vh) scale(.86)
    }

    60.8695652174% {
        -webkit-transform: translateX(23vw) translateY(-13vh) scale(.65);
                transform: translateX(23vw) translateY(-13vh) scale(.65)
    }

    65.2173913043% {
        -webkit-transform: translateX(12vw) translateY(45vh) scale(.41);
                transform: translateX(12vw) translateY(45vh) scale(.41)
    }

    69.5652173913% {
        -webkit-transform: translateX(32vw) translateY(-39vh) scale(.38);
                transform: translateX(32vw) translateY(-39vh) scale(.38)
    }

    73.9130434783% {
        -webkit-transform: translateX(45vw) translateY(-33vh) scale(.79);
                transform: translateX(45vw) translateY(-33vh) scale(.79)
    }

    78.2608695652% {
        -webkit-transform: translateX(6vw) translateY(16vh) scale(.68);
                transform: translateX(6vw) translateY(16vh) scale(.68)
    }

    82.6086956522% {
        -webkit-transform: translateX(18vw) translateY(-8vh) scale(.43);
                transform: translateX(18vw) translateY(-8vh) scale(.43)
    }

    86.9565217391% {
        -webkit-transform: translateX(-47vw) translateY(6vh) scale(.61);
                transform: translateX(-47vw) translateY(6vh) scale(.61)
    }

    91.3043478261% {
        -webkit-transform: translateX(18vw) translateY(-38vh) scale(.84);
                transform: translateX(18vw) translateY(-38vh) scale(.84)
    }

    95.652173913% {
        -webkit-transform: translateX(13vw) translateY(47vh) scale(.59);
                transform: translateX(13vw) translateY(47vh) scale(.59)
    }

    100% {
        -webkit-transform: translateX(0) translateY(37vh) scale(.75);
                transform: translateX(0) translateY(37vh) scale(.75)
    }
}

@keyframes fireflies_move2__1cvqI {
    0% {
        -webkit-transform: translateX(-6vw) translateY(49vh) scale(.47);
                transform: translateX(-6vw) translateY(49vh) scale(.47)
    }

    4.347826087% {
        -webkit-transform: translateX(-49vw) translateY(30vh) scale(.97);
                transform: translateX(-49vw) translateY(30vh) scale(.97)
    }

    8.6956521739% {
        -webkit-transform: translateX(-8vw) translateY(34vh) scale(.6);
                transform: translateX(-8vw) translateY(34vh) scale(.6)
    }

    13.0434782609% {
        -webkit-transform: translateX(21vw) translateY(-38vh) scale(.78);
                transform: translateX(21vw) translateY(-38vh) scale(.78)
    }

    17.3913043478% {
        -webkit-transform: translateX(-49vw) translateY(34vh) scale(.74);
                transform: translateX(-49vw) translateY(34vh) scale(.74)
    }

    21.7391304348% {
        -webkit-transform: translateX(48vw) translateY(10vh) scale(.7);
                transform: translateX(48vw) translateY(10vh) scale(.7)
    }

    26.0869565217% {
        -webkit-transform: translateX(9vw) translateY(40vh) scale(.3);
                transform: translateX(9vw) translateY(40vh) scale(.3)
    }

    30.4347826087% {
        -webkit-transform: translateX(36vw) translateY(-42vh) scale(.85);
                transform: translateX(36vw) translateY(-42vh) scale(.85)
    }

    34.7826086957% {
        -webkit-transform: translateX(26vw) translateY(-17vh) scale(.77);
                transform: translateX(26vw) translateY(-17vh) scale(.77)
    }

    39.1304347826% {
        -webkit-transform: translateX(-8vw) translateY(35vh) scale(.56);
                transform: translateX(-8vw) translateY(35vh) scale(.56)
    }

    43.4782608696% {
        -webkit-transform: translateX(22vw) translateY(20vh) scale(.43);
                transform: translateX(22vw) translateY(20vh) scale(.43)
    }

    47.8260869565% {
        -webkit-transform: translateX(-15vw) translateY(31vh) scale(.52);
                transform: translateX(-15vw) translateY(31vh) scale(.52)
    }

    52.1739130435% {
        -webkit-transform: translateX(19vw) translateY(-46vh) scale(.86);
                transform: translateX(19vw) translateY(-46vh) scale(.86)
    }

    56.5217391304% {
        -webkit-transform: translateX(-45vw) translateY(-28vh) scale(.86);
                transform: translateX(-45vw) translateY(-28vh) scale(.86)
    }

    60.8695652174% {
        -webkit-transform: translateX(23vw) translateY(-13vh) scale(.65);
                transform: translateX(23vw) translateY(-13vh) scale(.65)
    }

    65.2173913043% {
        -webkit-transform: translateX(12vw) translateY(45vh) scale(.41);
                transform: translateX(12vw) translateY(45vh) scale(.41)
    }

    69.5652173913% {
        -webkit-transform: translateX(32vw) translateY(-39vh) scale(.38);
                transform: translateX(32vw) translateY(-39vh) scale(.38)
    }

    73.9130434783% {
        -webkit-transform: translateX(45vw) translateY(-33vh) scale(.79);
                transform: translateX(45vw) translateY(-33vh) scale(.79)
    }

    78.2608695652% {
        -webkit-transform: translateX(6vw) translateY(16vh) scale(.68);
                transform: translateX(6vw) translateY(16vh) scale(.68)
    }

    82.6086956522% {
        -webkit-transform: translateX(18vw) translateY(-8vh) scale(.43);
                transform: translateX(18vw) translateY(-8vh) scale(.43)
    }

    86.9565217391% {
        -webkit-transform: translateX(-47vw) translateY(6vh) scale(.61);
                transform: translateX(-47vw) translateY(6vh) scale(.61)
    }

    91.3043478261% {
        -webkit-transform: translateX(18vw) translateY(-38vh) scale(.84);
                transform: translateX(18vw) translateY(-38vh) scale(.84)
    }

    95.652173913% {
        -webkit-transform: translateX(13vw) translateY(47vh) scale(.59);
                transform: translateX(13vw) translateY(47vh) scale(.59)
    }

    100% {
        -webkit-transform: translateX(0) translateY(37vh) scale(.75);
                transform: translateX(0) translateY(37vh) scale(.75)
    }
}

.fireflies_firefly__1W6BJ:nth-child(3) {
    -webkit-animation-name: fireflies_move3__odDMp;
            animation-name: fireflies_move3__odDMp
}

.fireflies_firefly__1W6BJ:nth-child(3)::before {
    -webkit-animation-duration: 11s;
            animation-duration: 11s
}

.fireflies_firefly__1W6BJ:nth-child(3)::after {
    -webkit-animation-duration: 11s, 10813ms;
            animation-duration: 11s, 10813ms;
    -webkit-animation-delay: 0s, 6375ms;
            animation-delay: 0s, 6375ms
}

@-webkit-keyframes fireflies_move3__odDMp {
    0% {
        -webkit-transform: translateX(-3vw) translateY(-39vh) scale(.41);
                transform: translateX(-3vw) translateY(-39vh) scale(.41)
    }

    5% {
        -webkit-transform: translateX(2vw) translateY(21vh) scale(.66);
                transform: translateX(2vw) translateY(21vh) scale(.66)
    }

    10% {
        -webkit-transform: translateX(-43vw) translateY(-49vh) scale(.6);
                transform: translateX(-43vw) translateY(-49vh) scale(.6)
    }

    15% {
        -webkit-transform: translateX(-15vw) translateY(-1vh) scale(.36);
                transform: translateX(-15vw) translateY(-1vh) scale(.36)
    }

    20% {
        -webkit-transform: translateX(-31vw) translateY(-27vh) scale(.42);
                transform: translateX(-31vw) translateY(-27vh) scale(.42)
    }

    25% {
        -webkit-transform: translateX(-31vw) translateY(-35vh) scale(.69);
                transform: translateX(-31vw) translateY(-35vh) scale(.69)
    }

    30% {
        -webkit-transform: translateX(33vw) translateY(31vh) scale(.93);
                transform: translateX(33vw) translateY(31vh) scale(.93)
    }

    35% {
        -webkit-transform: translateX(-7vw) translateY(-20vh) scale(.59);
                transform: translateX(-7vw) translateY(-20vh) scale(.59)
    }

    40% {
        -webkit-transform: translateX(18vw) translateY(19vh) scale(.66);
                transform: translateX(18vw) translateY(19vh) scale(.66)
    }

    45% {
        -webkit-transform: translateX(-25vw) translateY(-25vh) scale(.53);
                transform: translateX(-25vw) translateY(-25vh) scale(.53)
    }

    50% {
        -webkit-transform: translateX(6vw) translateY(36vh) scale(.61);
                transform: translateX(6vw) translateY(36vh) scale(.61)
    }

    55% {
        -webkit-transform: translateX(-10vw) translateY(-8vh) scale(.26);
                transform: translateX(-10vw) translateY(-8vh) scale(.26)
    }

    60% {
        -webkit-transform: translateX(-40vw) translateY(23vh) scale(.75);
                transform: translateX(-40vw) translateY(23vh) scale(.75)
    }

    65% {
        -webkit-transform: translateX(25vw) translateY(3vh) scale(.42);
                transform: translateX(25vw) translateY(3vh) scale(.42)
    }

    70% {
        -webkit-transform: translateX(-5vw) translateY(-2vh) scale(.61);
                transform: translateX(-5vw) translateY(-2vh) scale(.61)
    }

    75% {
        -webkit-transform: translateX(21vw) translateY(1vh) scale(.74);
                transform: translateX(21vw) translateY(1vh) scale(.74)
    }

    80% {
        -webkit-transform: translateX(14vw) translateY(-42vh) scale(.54);
                transform: translateX(14vw) translateY(-42vh) scale(.54)
    }

    85% {
        -webkit-transform: translateX(-38vw) translateY(-2vh) scale(.39);
                transform: translateX(-38vw) translateY(-2vh) scale(.39)
    }

    90% {
        -webkit-transform: translateX(4vw) translateY(-45vh) scale(.45);
                transform: translateX(4vw) translateY(-45vh) scale(.45)
    }

    95% {
        -webkit-transform: translateX(-34vw) translateY(-30vh) scale(.34);
                transform: translateX(-34vw) translateY(-30vh) scale(.34)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(12vh) scale(.87);
                transform: translateX(-26vw) translateY(12vh) scale(.87)
    }
}

@keyframes fireflies_move3__odDMp {
    0% {
        -webkit-transform: translateX(-3vw) translateY(-39vh) scale(.41);
                transform: translateX(-3vw) translateY(-39vh) scale(.41)
    }

    5% {
        -webkit-transform: translateX(2vw) translateY(21vh) scale(.66);
                transform: translateX(2vw) translateY(21vh) scale(.66)
    }

    10% {
        -webkit-transform: translateX(-43vw) translateY(-49vh) scale(.6);
                transform: translateX(-43vw) translateY(-49vh) scale(.6)
    }

    15% {
        -webkit-transform: translateX(-15vw) translateY(-1vh) scale(.36);
                transform: translateX(-15vw) translateY(-1vh) scale(.36)
    }

    20% {
        -webkit-transform: translateX(-31vw) translateY(-27vh) scale(.42);
                transform: translateX(-31vw) translateY(-27vh) scale(.42)
    }

    25% {
        -webkit-transform: translateX(-31vw) translateY(-35vh) scale(.69);
                transform: translateX(-31vw) translateY(-35vh) scale(.69)
    }

    30% {
        -webkit-transform: translateX(33vw) translateY(31vh) scale(.93);
                transform: translateX(33vw) translateY(31vh) scale(.93)
    }

    35% {
        -webkit-transform: translateX(-7vw) translateY(-20vh) scale(.59);
                transform: translateX(-7vw) translateY(-20vh) scale(.59)
    }

    40% {
        -webkit-transform: translateX(18vw) translateY(19vh) scale(.66);
                transform: translateX(18vw) translateY(19vh) scale(.66)
    }

    45% {
        -webkit-transform: translateX(-25vw) translateY(-25vh) scale(.53);
                transform: translateX(-25vw) translateY(-25vh) scale(.53)
    }

    50% {
        -webkit-transform: translateX(6vw) translateY(36vh) scale(.61);
                transform: translateX(6vw) translateY(36vh) scale(.61)
    }

    55% {
        -webkit-transform: translateX(-10vw) translateY(-8vh) scale(.26);
                transform: translateX(-10vw) translateY(-8vh) scale(.26)
    }

    60% {
        -webkit-transform: translateX(-40vw) translateY(23vh) scale(.75);
                transform: translateX(-40vw) translateY(23vh) scale(.75)
    }

    65% {
        -webkit-transform: translateX(25vw) translateY(3vh) scale(.42);
                transform: translateX(25vw) translateY(3vh) scale(.42)
    }

    70% {
        -webkit-transform: translateX(-5vw) translateY(-2vh) scale(.61);
                transform: translateX(-5vw) translateY(-2vh) scale(.61)
    }

    75% {
        -webkit-transform: translateX(21vw) translateY(1vh) scale(.74);
                transform: translateX(21vw) translateY(1vh) scale(.74)
    }

    80% {
        -webkit-transform: translateX(14vw) translateY(-42vh) scale(.54);
                transform: translateX(14vw) translateY(-42vh) scale(.54)
    }

    85% {
        -webkit-transform: translateX(-38vw) translateY(-2vh) scale(.39);
                transform: translateX(-38vw) translateY(-2vh) scale(.39)
    }

    90% {
        -webkit-transform: translateX(4vw) translateY(-45vh) scale(.45);
                transform: translateX(4vw) translateY(-45vh) scale(.45)
    }

    95% {
        -webkit-transform: translateX(-34vw) translateY(-30vh) scale(.34);
                transform: translateX(-34vw) translateY(-30vh) scale(.34)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(12vh) scale(.87);
                transform: translateX(-26vw) translateY(12vh) scale(.87)
    }
}

.fireflies_firefly__1W6BJ:nth-child(4) {
    -webkit-animation-name: fireflies_move4__1AQID;
            animation-name: fireflies_move4__1AQID
}

.fireflies_firefly__1W6BJ:nth-child(4)::before {
    -webkit-animation-duration: 15s;
            animation-duration: 15s
}

.fireflies_firefly__1W6BJ:nth-child(4)::after {
    -webkit-animation-duration: 15s, 7.2s;
            animation-duration: 15s, 7.2s;
    -webkit-animation-delay: 0s, 3559ms;
            animation-delay: 0s, 3559ms
}

@-webkit-keyframes fireflies_move4__1AQID {
    0% {
        -webkit-transform: translateX(-48vw) translateY(-39vh) scale(.63);
                transform: translateX(-48vw) translateY(-39vh) scale(.63)
    }

    3.7037037037% {
        -webkit-transform: translateX(-48vw) translateY(-23vh) scale(.34);
                transform: translateX(-48vw) translateY(-23vh) scale(.34)
    }

    7.4074074074% {
        -webkit-transform: translateX(48vw) translateY(30vh) scale(.78);
                transform: translateX(48vw) translateY(30vh) scale(.78)
    }

    11.1111111111% {
        -webkit-transform: translateX(0) translateY(11vh) scale(.53);
                transform: translateX(0) translateY(11vh) scale(.53)
    }

    14.8148148148% {
        -webkit-transform: translateX(-40vw) translateY(-44vh) scale(.32);
                transform: translateX(-40vw) translateY(-44vh) scale(.32)
    }

    18.5185185185% {
        -webkit-transform: translateX(-26vw) translateY(-15vh) scale(.81);
                transform: translateX(-26vw) translateY(-15vh) scale(.81)
    }

    22.2222222222% {
        -webkit-transform: translateX(-15vw) translateY(17vh) scale(.52);
                transform: translateX(-15vw) translateY(17vh) scale(.52)
    }

    25.9259259259% {
        -webkit-transform: translateX(-49vw) translateY(5vh) scale(.5);
                transform: translateX(-49vw) translateY(5vh) scale(.5)
    }

    29.6296296296% {
        -webkit-transform: translateX(0) translateY(34vh) scale(.76);
                transform: translateX(0) translateY(34vh) scale(.76)
    }

    33.3333333333% {
        -webkit-transform: translateX(25vw) translateY(-40vh) scale(.85);
                transform: translateX(25vw) translateY(-40vh) scale(.85)
    }

    37.037037037% {
        -webkit-transform: translateX(-41vw) translateY(21vh) scale(.64);
                transform: translateX(-41vw) translateY(21vh) scale(.64)
    }

    40.7407407407% {
        -webkit-transform: translateX(46vw) translateY(-45vh) scale(.88);
                transform: translateX(46vw) translateY(-45vh) scale(.88)
    }

    44.4444444444% {
        -webkit-transform: translateX(-9vw) translateY(22vh) scale(.5);
                transform: translateX(-9vw) translateY(22vh) scale(.5)
    }

    48.1481481481% {
        -webkit-transform: translateX(-31vw) translateY(48vh) scale(.9);
                transform: translateX(-31vw) translateY(48vh) scale(.9)
    }

    51.8518518519% {
        -webkit-transform: translateX(-26vw) translateY(-7vh) scale(.5);
                transform: translateX(-26vw) translateY(-7vh) scale(.5)
    }

    55.5555555556% {
        -webkit-transform: translateX(-12vw) translateY(32vh) scale(.31);
                transform: translateX(-12vw) translateY(32vh) scale(.31)
    }

    59.2592592593% {
        -webkit-transform: translateX(-18vw) translateY(46vh) scale(.83);
                transform: translateX(-18vw) translateY(46vh) scale(.83)
    }

    62.962962963% {
        -webkit-transform: translateX(22vw) translateY(-3vh) scale(.31);
                transform: translateX(22vw) translateY(-3vh) scale(.31)
    }

    66.6666666667% {
        -webkit-transform: translateX(22vw) translateY(48vh) scale(.43);
                transform: translateX(22vw) translateY(48vh) scale(.43)
    }

    70.3703703704% {
        -webkit-transform: translateX(-2vw) translateY(37vh) scale(.49);
                transform: translateX(-2vw) translateY(37vh) scale(.49)
    }

    74.0740740741% {
        -webkit-transform: translateX(-17vw) translateY(0) scale(.88);
                transform: translateX(-17vw) translateY(0) scale(.88)
    }

    77.7777777778% {
        -webkit-transform: translateX(-42vw) translateY(-37vh) scale(.98);
                transform: translateX(-42vw) translateY(-37vh) scale(.98)
    }

    81.4814814815% {
        -webkit-transform: translateX(44vw) translateY(-6vh) scale(.55);
                transform: translateX(44vw) translateY(-6vh) scale(.55)
    }

    85.1851851852% {
        -webkit-transform: translateX(-28vw) translateY(40vh) scale(.45);
                transform: translateX(-28vw) translateY(40vh) scale(.45)
    }

    88.8888888889% {
        -webkit-transform: translateX(13vw) translateY(49vh) scale(.9);
                transform: translateX(13vw) translateY(49vh) scale(.9)
    }

    92.5925925926% {
        -webkit-transform: translateX(-40vw) translateY(-27vh) scale(.42);
                transform: translateX(-40vw) translateY(-27vh) scale(.42)
    }

    96.2962962963% {
        -webkit-transform: translateX(31vw) translateY(-28vh) scale(.65);
                transform: translateX(31vw) translateY(-28vh) scale(.65)
    }

    100% {
        -webkit-transform: translateX(-38vw) translateY(36vh) scale(.52);
                transform: translateX(-38vw) translateY(36vh) scale(.52)
    }
}

@keyframes fireflies_move4__1AQID {
    0% {
        -webkit-transform: translateX(-48vw) translateY(-39vh) scale(.63);
                transform: translateX(-48vw) translateY(-39vh) scale(.63)
    }

    3.7037037037% {
        -webkit-transform: translateX(-48vw) translateY(-23vh) scale(.34);
                transform: translateX(-48vw) translateY(-23vh) scale(.34)
    }

    7.4074074074% {
        -webkit-transform: translateX(48vw) translateY(30vh) scale(.78);
                transform: translateX(48vw) translateY(30vh) scale(.78)
    }

    11.1111111111% {
        -webkit-transform: translateX(0) translateY(11vh) scale(.53);
                transform: translateX(0) translateY(11vh) scale(.53)
    }

    14.8148148148% {
        -webkit-transform: translateX(-40vw) translateY(-44vh) scale(.32);
                transform: translateX(-40vw) translateY(-44vh) scale(.32)
    }

    18.5185185185% {
        -webkit-transform: translateX(-26vw) translateY(-15vh) scale(.81);
                transform: translateX(-26vw) translateY(-15vh) scale(.81)
    }

    22.2222222222% {
        -webkit-transform: translateX(-15vw) translateY(17vh) scale(.52);
                transform: translateX(-15vw) translateY(17vh) scale(.52)
    }

    25.9259259259% {
        -webkit-transform: translateX(-49vw) translateY(5vh) scale(.5);
                transform: translateX(-49vw) translateY(5vh) scale(.5)
    }

    29.6296296296% {
        -webkit-transform: translateX(0) translateY(34vh) scale(.76);
                transform: translateX(0) translateY(34vh) scale(.76)
    }

    33.3333333333% {
        -webkit-transform: translateX(25vw) translateY(-40vh) scale(.85);
                transform: translateX(25vw) translateY(-40vh) scale(.85)
    }

    37.037037037% {
        -webkit-transform: translateX(-41vw) translateY(21vh) scale(.64);
                transform: translateX(-41vw) translateY(21vh) scale(.64)
    }

    40.7407407407% {
        -webkit-transform: translateX(46vw) translateY(-45vh) scale(.88);
                transform: translateX(46vw) translateY(-45vh) scale(.88)
    }

    44.4444444444% {
        -webkit-transform: translateX(-9vw) translateY(22vh) scale(.5);
                transform: translateX(-9vw) translateY(22vh) scale(.5)
    }

    48.1481481481% {
        -webkit-transform: translateX(-31vw) translateY(48vh) scale(.9);
                transform: translateX(-31vw) translateY(48vh) scale(.9)
    }

    51.8518518519% {
        -webkit-transform: translateX(-26vw) translateY(-7vh) scale(.5);
                transform: translateX(-26vw) translateY(-7vh) scale(.5)
    }

    55.5555555556% {
        -webkit-transform: translateX(-12vw) translateY(32vh) scale(.31);
                transform: translateX(-12vw) translateY(32vh) scale(.31)
    }

    59.2592592593% {
        -webkit-transform: translateX(-18vw) translateY(46vh) scale(.83);
                transform: translateX(-18vw) translateY(46vh) scale(.83)
    }

    62.962962963% {
        -webkit-transform: translateX(22vw) translateY(-3vh) scale(.31);
                transform: translateX(22vw) translateY(-3vh) scale(.31)
    }

    66.6666666667% {
        -webkit-transform: translateX(22vw) translateY(48vh) scale(.43);
                transform: translateX(22vw) translateY(48vh) scale(.43)
    }

    70.3703703704% {
        -webkit-transform: translateX(-2vw) translateY(37vh) scale(.49);
                transform: translateX(-2vw) translateY(37vh) scale(.49)
    }

    74.0740740741% {
        -webkit-transform: translateX(-17vw) translateY(0) scale(.88);
                transform: translateX(-17vw) translateY(0) scale(.88)
    }

    77.7777777778% {
        -webkit-transform: translateX(-42vw) translateY(-37vh) scale(.98);
                transform: translateX(-42vw) translateY(-37vh) scale(.98)
    }

    81.4814814815% {
        -webkit-transform: translateX(44vw) translateY(-6vh) scale(.55);
                transform: translateX(44vw) translateY(-6vh) scale(.55)
    }

    85.1851851852% {
        -webkit-transform: translateX(-28vw) translateY(40vh) scale(.45);
                transform: translateX(-28vw) translateY(40vh) scale(.45)
    }

    88.8888888889% {
        -webkit-transform: translateX(13vw) translateY(49vh) scale(.9);
                transform: translateX(13vw) translateY(49vh) scale(.9)
    }

    92.5925925926% {
        -webkit-transform: translateX(-40vw) translateY(-27vh) scale(.42);
                transform: translateX(-40vw) translateY(-27vh) scale(.42)
    }

    96.2962962963% {
        -webkit-transform: translateX(31vw) translateY(-28vh) scale(.65);
                transform: translateX(31vw) translateY(-28vh) scale(.65)
    }

    100% {
        -webkit-transform: translateX(-38vw) translateY(36vh) scale(.52);
                transform: translateX(-38vw) translateY(36vh) scale(.52)
    }
}

.fireflies_firefly__1W6BJ:nth-child(5) {
    -webkit-animation-name: fireflies_move5__1BcNA;
            animation-name: fireflies_move5__1BcNA
}

.fireflies_firefly__1W6BJ:nth-child(5)::before {
    -webkit-animation-duration: 12s;
            animation-duration: 12s
}

.fireflies_firefly__1W6BJ:nth-child(5)::after {
    -webkit-animation-duration: 12s, 10835ms;
            animation-duration: 12s, 10835ms;
    -webkit-animation-delay: 0s, 7.76s;
            animation-delay: 0s, 7.76s
}

@-webkit-keyframes fireflies_move5__1BcNA {
    0% {
        -webkit-transform: translateX(30vw) translateY(3vh) scale(.94);
                transform: translateX(30vw) translateY(3vh) scale(.94)
    }

    4.347826087% {
        -webkit-transform: translateX(-34vw) translateY(-4vh) scale(.7);
                transform: translateX(-34vw) translateY(-4vh) scale(.7)
    }

    8.6956521739% {
        -webkit-transform: translateX(-13vw) translateY(35vh) scale(.28);
                transform: translateX(-13vw) translateY(35vh) scale(.28)
    }

    13.0434782609% {
        -webkit-transform: translateX(44vw) translateY(31vh) scale(.48);
                transform: translateX(44vw) translateY(31vh) scale(.48)
    }

    17.3913043478% {
        -webkit-transform: translateX(23vw) translateY(36vh) scale(.72);
                transform: translateX(23vw) translateY(36vh) scale(.72)
    }

    21.7391304348% {
        -webkit-transform: translateX(-42vw) translateY(0) scale(.88);
                transform: translateX(-42vw) translateY(0) scale(.88)
    }

    26.0869565217% {
        -webkit-transform: translateX(-15vw) translateY(-19vh) scale(.79);
                transform: translateX(-15vw) translateY(-19vh) scale(.79)
    }

    30.4347826087% {
        -webkit-transform: translateX(-45vw) translateY(30vh) scale(.35);
                transform: translateX(-45vw) translateY(30vh) scale(.35)
    }

    34.7826086957% {
        -webkit-transform: translateX(17vw) translateY(-49vh) scale(.36);
                transform: translateX(17vw) translateY(-49vh) scale(.36)
    }

    39.1304347826% {
        -webkit-transform: translateX(-1vw) translateY(-43vh) scale(.7);
                transform: translateX(-1vw) translateY(-43vh) scale(.7)
    }

    43.4782608696% {
        -webkit-transform: translateX(-9vw) translateY(-46vh) scale(.59);
                transform: translateX(-9vw) translateY(-46vh) scale(.59)
    }

    47.8260869565% {
        -webkit-transform: translateX(-45vw) translateY(-47vh) scale(.65);
                transform: translateX(-45vw) translateY(-47vh) scale(.65)
    }

    52.1739130435% {
        -webkit-transform: translateX(-19vw) translateY(15vh) scale(.32);
                transform: translateX(-19vw) translateY(15vh) scale(.32)
    }

    56.5217391304% {
        -webkit-transform: translateX(-38vw) translateY(42vh) scale(.78);
                transform: translateX(-38vw) translateY(42vh) scale(.78)
    }

    60.8695652174% {
        -webkit-transform: translateX(-33vw) translateY(43vh) scale(.55);
                transform: translateX(-33vw) translateY(43vh) scale(.55)
    }

    65.2173913043% {
        -webkit-transform: translateX(23vw) translateY(32vh) scale(.46);
                transform: translateX(23vw) translateY(32vh) scale(.46)
    }

    69.5652173913% {
        -webkit-transform: translateX(-29vw) translateY(48vh) scale(.56);
                transform: translateX(-29vw) translateY(48vh) scale(.56)
    }

    73.9130434783% {
        -webkit-transform: translateX(-30vw) translateY(26vh) scale(.64);
                transform: translateX(-30vw) translateY(26vh) scale(.64)
    }

    78.2608695652% {
        -webkit-transform: translateX(7vw) translateY(-4vh) scale(.55);
                transform: translateX(7vw) translateY(-4vh) scale(.55)
    }

    82.6086956522% {
        -webkit-transform: translateX(36vw) translateY(-37vh) scale(.63);
                transform: translateX(36vw) translateY(-37vh) scale(.63)
    }

    86.9565217391% {
        -webkit-transform: translateX(-17vw) translateY(-15vh) scale(.98);
                transform: translateX(-17vw) translateY(-15vh) scale(.98)
    }

    91.3043478261% {
        -webkit-transform: translateX(-30vw) translateY(-23vh) scale(.37);
                transform: translateX(-30vw) translateY(-23vh) scale(.37)
    }

    95.652173913% {
        -webkit-transform: translateX(-16vw) translateY(-10vh) scale(.6);
                transform: translateX(-16vw) translateY(-10vh) scale(.6)
    }

    100% {
        -webkit-transform: translateX(-27vw) translateY(25vh) scale(.96);
                transform: translateX(-27vw) translateY(25vh) scale(.96)
    }
}

@keyframes fireflies_move5__1BcNA {
    0% {
        -webkit-transform: translateX(30vw) translateY(3vh) scale(.94);
                transform: translateX(30vw) translateY(3vh) scale(.94)
    }

    4.347826087% {
        -webkit-transform: translateX(-34vw) translateY(-4vh) scale(.7);
                transform: translateX(-34vw) translateY(-4vh) scale(.7)
    }

    8.6956521739% {
        -webkit-transform: translateX(-13vw) translateY(35vh) scale(.28);
                transform: translateX(-13vw) translateY(35vh) scale(.28)
    }

    13.0434782609% {
        -webkit-transform: translateX(44vw) translateY(31vh) scale(.48);
                transform: translateX(44vw) translateY(31vh) scale(.48)
    }

    17.3913043478% {
        -webkit-transform: translateX(23vw) translateY(36vh) scale(.72);
                transform: translateX(23vw) translateY(36vh) scale(.72)
    }

    21.7391304348% {
        -webkit-transform: translateX(-42vw) translateY(0) scale(.88);
                transform: translateX(-42vw) translateY(0) scale(.88)
    }

    26.0869565217% {
        -webkit-transform: translateX(-15vw) translateY(-19vh) scale(.79);
                transform: translateX(-15vw) translateY(-19vh) scale(.79)
    }

    30.4347826087% {
        -webkit-transform: translateX(-45vw) translateY(30vh) scale(.35);
                transform: translateX(-45vw) translateY(30vh) scale(.35)
    }

    34.7826086957% {
        -webkit-transform: translateX(17vw) translateY(-49vh) scale(.36);
                transform: translateX(17vw) translateY(-49vh) scale(.36)
    }

    39.1304347826% {
        -webkit-transform: translateX(-1vw) translateY(-43vh) scale(.7);
                transform: translateX(-1vw) translateY(-43vh) scale(.7)
    }

    43.4782608696% {
        -webkit-transform: translateX(-9vw) translateY(-46vh) scale(.59);
                transform: translateX(-9vw) translateY(-46vh) scale(.59)
    }

    47.8260869565% {
        -webkit-transform: translateX(-45vw) translateY(-47vh) scale(.65);
                transform: translateX(-45vw) translateY(-47vh) scale(.65)
    }

    52.1739130435% {
        -webkit-transform: translateX(-19vw) translateY(15vh) scale(.32);
                transform: translateX(-19vw) translateY(15vh) scale(.32)
    }

    56.5217391304% {
        -webkit-transform: translateX(-38vw) translateY(42vh) scale(.78);
                transform: translateX(-38vw) translateY(42vh) scale(.78)
    }

    60.8695652174% {
        -webkit-transform: translateX(-33vw) translateY(43vh) scale(.55);
                transform: translateX(-33vw) translateY(43vh) scale(.55)
    }

    65.2173913043% {
        -webkit-transform: translateX(23vw) translateY(32vh) scale(.46);
                transform: translateX(23vw) translateY(32vh) scale(.46)
    }

    69.5652173913% {
        -webkit-transform: translateX(-29vw) translateY(48vh) scale(.56);
                transform: translateX(-29vw) translateY(48vh) scale(.56)
    }

    73.9130434783% {
        -webkit-transform: translateX(-30vw) translateY(26vh) scale(.64);
                transform: translateX(-30vw) translateY(26vh) scale(.64)
    }

    78.2608695652% {
        -webkit-transform: translateX(7vw) translateY(-4vh) scale(.55);
                transform: translateX(7vw) translateY(-4vh) scale(.55)
    }

    82.6086956522% {
        -webkit-transform: translateX(36vw) translateY(-37vh) scale(.63);
                transform: translateX(36vw) translateY(-37vh) scale(.63)
    }

    86.9565217391% {
        -webkit-transform: translateX(-17vw) translateY(-15vh) scale(.98);
                transform: translateX(-17vw) translateY(-15vh) scale(.98)
    }

    91.3043478261% {
        -webkit-transform: translateX(-30vw) translateY(-23vh) scale(.37);
                transform: translateX(-30vw) translateY(-23vh) scale(.37)
    }

    95.652173913% {
        -webkit-transform: translateX(-16vw) translateY(-10vh) scale(.6);
                transform: translateX(-16vw) translateY(-10vh) scale(.6)
    }

    100% {
        -webkit-transform: translateX(-27vw) translateY(25vh) scale(.96);
                transform: translateX(-27vw) translateY(25vh) scale(.96)
    }
}

.fireflies_firefly__1W6BJ:nth-child(6) {
    -webkit-animation-name: fireflies_move6__3jcCJ;
            animation-name: fireflies_move6__3jcCJ
}

.fireflies_firefly__1W6BJ:nth-child(6)::before {
    -webkit-animation-duration: 15s;
            animation-duration: 15s
}

.fireflies_firefly__1W6BJ:nth-child(6)::after {
    -webkit-animation-duration: 15s, 7929ms;
            animation-duration: 15s, 7929ms;
    -webkit-animation-delay: 0s, 3251ms;
            animation-delay: 0s, 3251ms
}

@-webkit-keyframes fireflies_move6__3jcCJ {
    0% {
        -webkit-transform: translateX(-45vw) translateY(3vh) scale(.94);
                transform: translateX(-45vw) translateY(3vh) scale(.94)
    }

    4.5454545455% {
        -webkit-transform: translateX(46vw) translateY(31vh) scale(.66);
                transform: translateX(46vw) translateY(31vh) scale(.66)
    }

    9.0909090909% {
        -webkit-transform: translateX(16vw) translateY(25vh) scale(.94);
                transform: translateX(16vw) translateY(25vh) scale(.94)
    }

    13.6363636364% {
        -webkit-transform: translateX(48vw) translateY(19vh) scale(.97);
                transform: translateX(48vw) translateY(19vh) scale(.97)
    }

    18.1818181818% {
        -webkit-transform: translateX(34vw) translateY(6vh) scale(.41);
                transform: translateX(34vw) translateY(6vh) scale(.41)
    }

    22.7272727273% {
        -webkit-transform: translateX(31vw) translateY(3vh) scale(.91);
                transform: translateX(31vw) translateY(3vh) scale(.91)
    }

    27.2727272727% {
        -webkit-transform: translateX(14vw) translateY(-40vh) scale(.98);
                transform: translateX(14vw) translateY(-40vh) scale(.98)
    }

    31.8181818182% {
        -webkit-transform: translateX(8vw) translateY(-33vh) scale(.79);
                transform: translateX(8vw) translateY(-33vh) scale(.79)
    }

    36.3636363636% {
        -webkit-transform: translateX(30vw) translateY(46vh) scale(.59);
                transform: translateX(30vw) translateY(46vh) scale(.59)
    }

    40.9090909091% {
        -webkit-transform: translateX(-38vw) translateY(-42vh) scale(.93);
                transform: translateX(-38vw) translateY(-42vh) scale(.93)
    }

    45.4545454545% {
        -webkit-transform: translateX(-41vw) translateY(3vh) scale(.84);
                transform: translateX(-41vw) translateY(3vh) scale(.84)
    }

    50% {
        -webkit-transform: translateX(-22vw) translateY(25vh) scale(.5);
                transform: translateX(-22vw) translateY(25vh) scale(.5)
    }

    54.5454545455% {
        -webkit-transform: translateX(-47vw) translateY(2vh) scale(.61);
                transform: translateX(-47vw) translateY(2vh) scale(.61)
    }

    59.0909090909% {
        -webkit-transform: translateX(-16vw) translateY(-18vh) scale(.6);
                transform: translateX(-16vw) translateY(-18vh) scale(.6)
    }

    63.6363636364% {
        -webkit-transform: translateX(-6vw) translateY(24vh) scale(.35);
                transform: translateX(-6vw) translateY(24vh) scale(.35)
    }

    68.1818181818% {
        -webkit-transform: translateX(-26vw) translateY(-43vh) scale(.28);
                transform: translateX(-26vw) translateY(-43vh) scale(.28)
    }

    72.7272727273% {
        -webkit-transform: translateX(25vw) translateY(0) scale(.38);
                transform: translateX(25vw) translateY(0) scale(.38)
    }

    77.2727272727% {
        -webkit-transform: translateX(47vw) translateY(24vh) scale(.81);
                transform: translateX(47vw) translateY(24vh) scale(.81)
    }

    81.8181818182% {
        -webkit-transform: translateX(6vw) translateY(2vh) scale(.85);
                transform: translateX(6vw) translateY(2vh) scale(.85)
    }

    86.3636363636% {
        -webkit-transform: translateX(41vw) translateY(-19vh) scale(.6);
                transform: translateX(41vw) translateY(-19vh) scale(.6)
    }

    90.9090909091% {
        -webkit-transform: translateX(27vw) translateY(-20vh) scale(.69);
                transform: translateX(27vw) translateY(-20vh) scale(.69)
    }

    95.4545454545% {
        -webkit-transform: translateX(20vw) translateY(-26vh) scale(.56);
                transform: translateX(20vw) translateY(-26vh) scale(.56)
    }

    100% {
        -webkit-transform: translateX(34vw) translateY(28vh) scale(.85);
                transform: translateX(34vw) translateY(28vh) scale(.85)
    }
}

@keyframes fireflies_move6__3jcCJ {
    0% {
        -webkit-transform: translateX(-45vw) translateY(3vh) scale(.94);
                transform: translateX(-45vw) translateY(3vh) scale(.94)
    }

    4.5454545455% {
        -webkit-transform: translateX(46vw) translateY(31vh) scale(.66);
                transform: translateX(46vw) translateY(31vh) scale(.66)
    }

    9.0909090909% {
        -webkit-transform: translateX(16vw) translateY(25vh) scale(.94);
                transform: translateX(16vw) translateY(25vh) scale(.94)
    }

    13.6363636364% {
        -webkit-transform: translateX(48vw) translateY(19vh) scale(.97);
                transform: translateX(48vw) translateY(19vh) scale(.97)
    }

    18.1818181818% {
        -webkit-transform: translateX(34vw) translateY(6vh) scale(.41);
                transform: translateX(34vw) translateY(6vh) scale(.41)
    }

    22.7272727273% {
        -webkit-transform: translateX(31vw) translateY(3vh) scale(.91);
                transform: translateX(31vw) translateY(3vh) scale(.91)
    }

    27.2727272727% {
        -webkit-transform: translateX(14vw) translateY(-40vh) scale(.98);
                transform: translateX(14vw) translateY(-40vh) scale(.98)
    }

    31.8181818182% {
        -webkit-transform: translateX(8vw) translateY(-33vh) scale(.79);
                transform: translateX(8vw) translateY(-33vh) scale(.79)
    }

    36.3636363636% {
        -webkit-transform: translateX(30vw) translateY(46vh) scale(.59);
                transform: translateX(30vw) translateY(46vh) scale(.59)
    }

    40.9090909091% {
        -webkit-transform: translateX(-38vw) translateY(-42vh) scale(.93);
                transform: translateX(-38vw) translateY(-42vh) scale(.93)
    }

    45.4545454545% {
        -webkit-transform: translateX(-41vw) translateY(3vh) scale(.84);
                transform: translateX(-41vw) translateY(3vh) scale(.84)
    }

    50% {
        -webkit-transform: translateX(-22vw) translateY(25vh) scale(.5);
                transform: translateX(-22vw) translateY(25vh) scale(.5)
    }

    54.5454545455% {
        -webkit-transform: translateX(-47vw) translateY(2vh) scale(.61);
                transform: translateX(-47vw) translateY(2vh) scale(.61)
    }

    59.0909090909% {
        -webkit-transform: translateX(-16vw) translateY(-18vh) scale(.6);
                transform: translateX(-16vw) translateY(-18vh) scale(.6)
    }

    63.6363636364% {
        -webkit-transform: translateX(-6vw) translateY(24vh) scale(.35);
                transform: translateX(-6vw) translateY(24vh) scale(.35)
    }

    68.1818181818% {
        -webkit-transform: translateX(-26vw) translateY(-43vh) scale(.28);
                transform: translateX(-26vw) translateY(-43vh) scale(.28)
    }

    72.7272727273% {
        -webkit-transform: translateX(25vw) translateY(0) scale(.38);
                transform: translateX(25vw) translateY(0) scale(.38)
    }

    77.2727272727% {
        -webkit-transform: translateX(47vw) translateY(24vh) scale(.81);
                transform: translateX(47vw) translateY(24vh) scale(.81)
    }

    81.8181818182% {
        -webkit-transform: translateX(6vw) translateY(2vh) scale(.85);
                transform: translateX(6vw) translateY(2vh) scale(.85)
    }

    86.3636363636% {
        -webkit-transform: translateX(41vw) translateY(-19vh) scale(.6);
                transform: translateX(41vw) translateY(-19vh) scale(.6)
    }

    90.9090909091% {
        -webkit-transform: translateX(27vw) translateY(-20vh) scale(.69);
                transform: translateX(27vw) translateY(-20vh) scale(.69)
    }

    95.4545454545% {
        -webkit-transform: translateX(20vw) translateY(-26vh) scale(.56);
                transform: translateX(20vw) translateY(-26vh) scale(.56)
    }

    100% {
        -webkit-transform: translateX(34vw) translateY(28vh) scale(.85);
                transform: translateX(34vw) translateY(28vh) scale(.85)
    }
}

.fireflies_firefly__1W6BJ:nth-child(7) {
    -webkit-animation-name: fireflies_move7__Je9eN;
            animation-name: fireflies_move7__Je9eN
}

.fireflies_firefly__1W6BJ:nth-child(7)::before {
    -webkit-animation-duration: 13s;
            animation-duration: 13s
}

.fireflies_firefly__1W6BJ:nth-child(7)::after {
    -webkit-animation-duration: 13s, 7649ms;
            animation-duration: 13s, 7649ms;
    -webkit-animation-delay: 0s, 7425ms;
            animation-delay: 0s, 7425ms
}

@-webkit-keyframes fireflies_move7__Je9eN {
    0% {
        -webkit-transform: translateX(-20vw) translateY(13vh) scale(.95);
                transform: translateX(-20vw) translateY(13vh) scale(.95)
    }

    5.2631578947% {
        -webkit-transform: translateX(8vw) translateY(40vh) scale(.32);
                transform: translateX(8vw) translateY(40vh) scale(.32)
    }

    10.5263157895% {
        -webkit-transform: translateX(10vw) translateY(36vh) scale(.87);
                transform: translateX(10vw) translateY(36vh) scale(.87)
    }

    15.7894736842% {
        -webkit-transform: translateX(-40vw) translateY(32vh) scale(.28);
                transform: translateX(-40vw) translateY(32vh) scale(.28)
    }

    21.0526315789% {
        -webkit-transform: translateX(-47vw) translateY(-8vh) scale(.52);
                transform: translateX(-47vw) translateY(-8vh) scale(.52)
    }

    26.3157894737% {
        -webkit-transform: translateX(47vw) translateY(-40vh) scale(.92);
                transform: translateX(47vw) translateY(-40vh) scale(.92)
    }

    31.5789473684% {
        -webkit-transform: translateX(-46vw) translateY(-31vh) scale(.53);
                transform: translateX(-46vw) translateY(-31vh) scale(.53)
    }

    36.8421052632% {
        -webkit-transform: translateX(-39vw) translateY(-13vh) scale(.31);
                transform: translateX(-39vw) translateY(-13vh) scale(.31)
    }

    42.1052631579% {
        -webkit-transform: translateX(40vw) translateY(14vh) scale(.61);
                transform: translateX(40vw) translateY(14vh) scale(.61)
    }

    47.3684210526% {
        -webkit-transform: translateX(26vw) translateY(-15vh) scale(.98);
                transform: translateX(26vw) translateY(-15vh) scale(.98)
    }

    52.6315789474% {
        -webkit-transform: translateX(46vw) translateY(1vh) scale(.78);
                transform: translateX(46vw) translateY(1vh) scale(.78)
    }

    57.8947368421% {
        -webkit-transform: translateX(-7vw) translateY(-4vh) scale(.43);
                transform: translateX(-7vw) translateY(-4vh) scale(.43)
    }

    63.1578947368% {
        -webkit-transform: translateX(16vw) translateY(2vh) scale(.33);
                transform: translateX(16vw) translateY(2vh) scale(.33)
    }

    68.4210526316% {
        -webkit-transform: translateX(-4vw) translateY(11vh) scale(.59);
                transform: translateX(-4vw) translateY(11vh) scale(.59)
    }

    73.6842105263% {
        -webkit-transform: translateX(-21vw) translateY(-4vh) scale(.35);
                transform: translateX(-21vw) translateY(-4vh) scale(.35)
    }

    78.9473684211% {
        -webkit-transform: translateX(1vw) translateY(-37vh) scale(.85);
                transform: translateX(1vw) translateY(-37vh) scale(.85)
    }

    84.2105263158% {
        -webkit-transform: translateX(26vw) translateY(-38vh) scale(.44);
                transform: translateX(26vw) translateY(-38vh) scale(.44)
    }

    89.4736842105% {
        -webkit-transform: translateX(-37vw) translateY(-35vh) scale(.35);
                transform: translateX(-37vw) translateY(-35vh) scale(.35)
    }

    94.7368421053% {
        -webkit-transform: translateX(-39vw) translateY(3vh) scale(.27);
                transform: translateX(-39vw) translateY(3vh) scale(.27)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(29vh) scale(.65);
                transform: translateX(-26vw) translateY(29vh) scale(.65)
    }
}

@keyframes fireflies_move7__Je9eN {
    0% {
        -webkit-transform: translateX(-20vw) translateY(13vh) scale(.95);
                transform: translateX(-20vw) translateY(13vh) scale(.95)
    }

    5.2631578947% {
        -webkit-transform: translateX(8vw) translateY(40vh) scale(.32);
                transform: translateX(8vw) translateY(40vh) scale(.32)
    }

    10.5263157895% {
        -webkit-transform: translateX(10vw) translateY(36vh) scale(.87);
                transform: translateX(10vw) translateY(36vh) scale(.87)
    }

    15.7894736842% {
        -webkit-transform: translateX(-40vw) translateY(32vh) scale(.28);
                transform: translateX(-40vw) translateY(32vh) scale(.28)
    }

    21.0526315789% {
        -webkit-transform: translateX(-47vw) translateY(-8vh) scale(.52);
                transform: translateX(-47vw) translateY(-8vh) scale(.52)
    }

    26.3157894737% {
        -webkit-transform: translateX(47vw) translateY(-40vh) scale(.92);
                transform: translateX(47vw) translateY(-40vh) scale(.92)
    }

    31.5789473684% {
        -webkit-transform: translateX(-46vw) translateY(-31vh) scale(.53);
                transform: translateX(-46vw) translateY(-31vh) scale(.53)
    }

    36.8421052632% {
        -webkit-transform: translateX(-39vw) translateY(-13vh) scale(.31);
                transform: translateX(-39vw) translateY(-13vh) scale(.31)
    }

    42.1052631579% {
        -webkit-transform: translateX(40vw) translateY(14vh) scale(.61);
                transform: translateX(40vw) translateY(14vh) scale(.61)
    }

    47.3684210526% {
        -webkit-transform: translateX(26vw) translateY(-15vh) scale(.98);
                transform: translateX(26vw) translateY(-15vh) scale(.98)
    }

    52.6315789474% {
        -webkit-transform: translateX(46vw) translateY(1vh) scale(.78);
                transform: translateX(46vw) translateY(1vh) scale(.78)
    }

    57.8947368421% {
        -webkit-transform: translateX(-7vw) translateY(-4vh) scale(.43);
                transform: translateX(-7vw) translateY(-4vh) scale(.43)
    }

    63.1578947368% {
        -webkit-transform: translateX(16vw) translateY(2vh) scale(.33);
                transform: translateX(16vw) translateY(2vh) scale(.33)
    }

    68.4210526316% {
        -webkit-transform: translateX(-4vw) translateY(11vh) scale(.59);
                transform: translateX(-4vw) translateY(11vh) scale(.59)
    }

    73.6842105263% {
        -webkit-transform: translateX(-21vw) translateY(-4vh) scale(.35);
                transform: translateX(-21vw) translateY(-4vh) scale(.35)
    }

    78.9473684211% {
        -webkit-transform: translateX(1vw) translateY(-37vh) scale(.85);
                transform: translateX(1vw) translateY(-37vh) scale(.85)
    }

    84.2105263158% {
        -webkit-transform: translateX(26vw) translateY(-38vh) scale(.44);
                transform: translateX(26vw) translateY(-38vh) scale(.44)
    }

    89.4736842105% {
        -webkit-transform: translateX(-37vw) translateY(-35vh) scale(.35);
                transform: translateX(-37vw) translateY(-35vh) scale(.35)
    }

    94.7368421053% {
        -webkit-transform: translateX(-39vw) translateY(3vh) scale(.27);
                transform: translateX(-39vw) translateY(3vh) scale(.27)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(29vh) scale(.65);
                transform: translateX(-26vw) translateY(29vh) scale(.65)
    }
}

.fireflies_firefly__1W6BJ:nth-child(8) {
    -webkit-animation-name: fireflies_move8__3Q_JD;
            animation-name: fireflies_move8__3Q_JD
}

.fireflies_firefly__1W6BJ:nth-child(8)::before {
    -webkit-animation-duration: 14s;
            animation-duration: 14s
}

.fireflies_firefly__1W6BJ:nth-child(8)::after {
    -webkit-animation-duration: 14s, 10189ms;
            animation-duration: 14s, 10189ms;
    -webkit-animation-delay: 0s, 1271ms;
            animation-delay: 0s, 1271ms
}

@-webkit-keyframes fireflies_move8__3Q_JD {
    0% {
        -webkit-transform: translateX(38vw) translateY(30vh) scale(.33);
                transform: translateX(38vw) translateY(30vh) scale(.33)
    }

    4.1666666667% {
        -webkit-transform: translateX(-35vw) translateY(15vh) scale(.75);
                transform: translateX(-35vw) translateY(15vh) scale(.75)
    }

    8.3333333333% {
        -webkit-transform: translateX(-40vw) translateY(39vh) scale(.85);
                transform: translateX(-40vw) translateY(39vh) scale(.85)
    }

    12.5% {
        -webkit-transform: translateX(39vw) translateY(22vh) scale(.77);
                transform: translateX(39vw) translateY(22vh) scale(.77)
    }

    16.6666666667% {
        -webkit-transform: translateX(-9vw) translateY(-41vh) scale(.98);
                transform: translateX(-9vw) translateY(-41vh) scale(.98)
    }

    20.8333333333% {
        -webkit-transform: translateX(14vw) translateY(12vh) scale(.99);
                transform: translateX(14vw) translateY(12vh) scale(.99)
    }

    25% {
        -webkit-transform: translateX(7vw) translateY(40vh) scale(.97);
                transform: translateX(7vw) translateY(40vh) scale(.97)
    }

    29.1666666667% {
        -webkit-transform: translateX(-16vw) translateY(-43vh) scale(.82);
                transform: translateX(-16vw) translateY(-43vh) scale(.82)
    }

    33.3333333333% {
        -webkit-transform: translateX(0) translateY(0) scale(.4);
                transform: translateX(0) translateY(0) scale(.4)
    }

    37.5% {
        -webkit-transform: translateX(25vw) translateY(47vh) scale(.86);
                transform: translateX(25vw) translateY(47vh) scale(.86)
    }

    41.6666666667% {
        -webkit-transform: translateX(1vw) translateY(-9vh) scale(.8);
                transform: translateX(1vw) translateY(-9vh) scale(.8)
    }

    45.8333333333% {
        -webkit-transform: translateX(-49vw) translateY(-3vh) scale(.29);
                transform: translateX(-49vw) translateY(-3vh) scale(.29)
    }

    50% {
        -webkit-transform: translateX(34vw) translateY(-1vh) scale(.75);
                transform: translateX(34vw) translateY(-1vh) scale(.75)
    }

    54.1666666667% {
        -webkit-transform: translateX(47vw) translateY(13vh) scale(.83);
                transform: translateX(47vw) translateY(13vh) scale(.83)
    }

    58.3333333333% {
        -webkit-transform: translateX(33vw) translateY(-38vh) scale(.35);
                transform: translateX(33vw) translateY(-38vh) scale(.35)
    }

    62.5% {
        -webkit-transform: translateX(1vw) translateY(10vh) scale(.63);
                transform: translateX(1vw) translateY(10vh) scale(.63)
    }

    66.6666666667% {
        -webkit-transform: translateX(-7vw) translateY(-21vh) scale(.87);
                transform: translateX(-7vw) translateY(-21vh) scale(.87)
    }

    70.8333333333% {
        -webkit-transform: translateX(5vw) translateY(3vh) scale(.52);
                transform: translateX(5vw) translateY(3vh) scale(.52)
    }

    75% {
        -webkit-transform: translateX(4vw) translateY(42vh) scale(.77);
                transform: translateX(4vw) translateY(42vh) scale(.77)
    }

    79.1666666667% {
        -webkit-transform: translateX(-29vw) translateY(40vh) scale(.74);
                transform: translateX(-29vw) translateY(40vh) scale(.74)
    }

    83.3333333333% {
        -webkit-transform: translateX(-41vw) translateY(-45vh) scale(.44);
                transform: translateX(-41vw) translateY(-45vh) scale(.44)
    }

    87.5% {
        -webkit-transform: translateX(46vw) translateY(-33vh) scale(.55);
                transform: translateX(46vw) translateY(-33vh) scale(.55)
    }

    91.6666666667% {
        -webkit-transform: translateX(6vw) translateY(-28vh) scale(.89);
                transform: translateX(6vw) translateY(-28vh) scale(.89)
    }

    95.8333333333% {
        -webkit-transform: translateX(-17vw) translateY(14vh) scale(.69);
                transform: translateX(-17vw) translateY(14vh) scale(.69)
    }

    100% {
        -webkit-transform: translateX(-42vw) translateY(19vh) scale(.47);
                transform: translateX(-42vw) translateY(19vh) scale(.47)
    }
}

@keyframes fireflies_move8__3Q_JD {
    0% {
        -webkit-transform: translateX(38vw) translateY(30vh) scale(.33);
                transform: translateX(38vw) translateY(30vh) scale(.33)
    }

    4.1666666667% {
        -webkit-transform: translateX(-35vw) translateY(15vh) scale(.75);
                transform: translateX(-35vw) translateY(15vh) scale(.75)
    }

    8.3333333333% {
        -webkit-transform: translateX(-40vw) translateY(39vh) scale(.85);
                transform: translateX(-40vw) translateY(39vh) scale(.85)
    }

    12.5% {
        -webkit-transform: translateX(39vw) translateY(22vh) scale(.77);
                transform: translateX(39vw) translateY(22vh) scale(.77)
    }

    16.6666666667% {
        -webkit-transform: translateX(-9vw) translateY(-41vh) scale(.98);
                transform: translateX(-9vw) translateY(-41vh) scale(.98)
    }

    20.8333333333% {
        -webkit-transform: translateX(14vw) translateY(12vh) scale(.99);
                transform: translateX(14vw) translateY(12vh) scale(.99)
    }

    25% {
        -webkit-transform: translateX(7vw) translateY(40vh) scale(.97);
                transform: translateX(7vw) translateY(40vh) scale(.97)
    }

    29.1666666667% {
        -webkit-transform: translateX(-16vw) translateY(-43vh) scale(.82);
                transform: translateX(-16vw) translateY(-43vh) scale(.82)
    }

    33.3333333333% {
        -webkit-transform: translateX(0) translateY(0) scale(.4);
                transform: translateX(0) translateY(0) scale(.4)
    }

    37.5% {
        -webkit-transform: translateX(25vw) translateY(47vh) scale(.86);
                transform: translateX(25vw) translateY(47vh) scale(.86)
    }

    41.6666666667% {
        -webkit-transform: translateX(1vw) translateY(-9vh) scale(.8);
                transform: translateX(1vw) translateY(-9vh) scale(.8)
    }

    45.8333333333% {
        -webkit-transform: translateX(-49vw) translateY(-3vh) scale(.29);
                transform: translateX(-49vw) translateY(-3vh) scale(.29)
    }

    50% {
        -webkit-transform: translateX(34vw) translateY(-1vh) scale(.75);
                transform: translateX(34vw) translateY(-1vh) scale(.75)
    }

    54.1666666667% {
        -webkit-transform: translateX(47vw) translateY(13vh) scale(.83);
                transform: translateX(47vw) translateY(13vh) scale(.83)
    }

    58.3333333333% {
        -webkit-transform: translateX(33vw) translateY(-38vh) scale(.35);
                transform: translateX(33vw) translateY(-38vh) scale(.35)
    }

    62.5% {
        -webkit-transform: translateX(1vw) translateY(10vh) scale(.63);
                transform: translateX(1vw) translateY(10vh) scale(.63)
    }

    66.6666666667% {
        -webkit-transform: translateX(-7vw) translateY(-21vh) scale(.87);
                transform: translateX(-7vw) translateY(-21vh) scale(.87)
    }

    70.8333333333% {
        -webkit-transform: translateX(5vw) translateY(3vh) scale(.52);
                transform: translateX(5vw) translateY(3vh) scale(.52)
    }

    75% {
        -webkit-transform: translateX(4vw) translateY(42vh) scale(.77);
                transform: translateX(4vw) translateY(42vh) scale(.77)
    }

    79.1666666667% {
        -webkit-transform: translateX(-29vw) translateY(40vh) scale(.74);
                transform: translateX(-29vw) translateY(40vh) scale(.74)
    }

    83.3333333333% {
        -webkit-transform: translateX(-41vw) translateY(-45vh) scale(.44);
                transform: translateX(-41vw) translateY(-45vh) scale(.44)
    }

    87.5% {
        -webkit-transform: translateX(46vw) translateY(-33vh) scale(.55);
                transform: translateX(46vw) translateY(-33vh) scale(.55)
    }

    91.6666666667% {
        -webkit-transform: translateX(6vw) translateY(-28vh) scale(.89);
                transform: translateX(6vw) translateY(-28vh) scale(.89)
    }

    95.8333333333% {
        -webkit-transform: translateX(-17vw) translateY(14vh) scale(.69);
                transform: translateX(-17vw) translateY(14vh) scale(.69)
    }

    100% {
        -webkit-transform: translateX(-42vw) translateY(19vh) scale(.47);
                transform: translateX(-42vw) translateY(19vh) scale(.47)
    }
}

.fireflies_firefly__1W6BJ:nth-child(9) {
    -webkit-animation-name: fireflies_move9__3BUGz;
            animation-name: fireflies_move9__3BUGz
}

.fireflies_firefly__1W6BJ:nth-child(9)::before {
    -webkit-animation-duration: 16s;
            animation-duration: 16s
}

.fireflies_firefly__1W6BJ:nth-child(9)::after {
    -webkit-animation-duration: 16s, 6659ms;
            animation-duration: 16s, 6659ms;
    -webkit-animation-delay: 0s, 5.29s;
            animation-delay: 0s, 5.29s
}

@-webkit-keyframes fireflies_move9__3BUGz {
    0% {
        -webkit-transform: translateX(36vw) translateY(-37vh) scale(.6);
                transform: translateX(36vw) translateY(-37vh) scale(.6)
    }

    5% {
        -webkit-transform: translateX(44vw) translateY(47vh) scale(.42);
                transform: translateX(44vw) translateY(47vh) scale(.42)
    }

    10% {
        -webkit-transform: translateX(6vw) translateY(12vh) scale(.26);
                transform: translateX(6vw) translateY(12vh) scale(.26)
    }

    15% {
        -webkit-transform: translateX(-29vw) translateY(-16vh) scale(.73);
                transform: translateX(-29vw) translateY(-16vh) scale(.73)
    }

    20% {
        -webkit-transform: translateX(-4vw) translateY(11vh) scale(.6);
                transform: translateX(-4vw) translateY(11vh) scale(.6)
    }

    25% {
        -webkit-transform: translateX(33vw) translateY(48vh) scale(.26);
                transform: translateX(33vw) translateY(48vh) scale(.26)
    }

    30% {
        -webkit-transform: translateX(-44vw) translateY(15vh) scale(.84);
                transform: translateX(-44vw) translateY(15vh) scale(.84)
    }

    35% {
        -webkit-transform: translateX(5vw) translateY(-44vh) scale(.84);
                transform: translateX(5vw) translateY(-44vh) scale(.84)
    }

    40% {
        -webkit-transform: translateX(16vw) translateY(-18vh) scale(.79);
                transform: translateX(16vw) translateY(-18vh) scale(.79)
    }

    45% {
        -webkit-transform: translateX(-19vw) translateY(3vh) scale(.78);
                transform: translateX(-19vw) translateY(3vh) scale(.78)
    }

    50% {
        -webkit-transform: translateX(-26vw) translateY(-28vh) scale(.44);
                transform: translateX(-26vw) translateY(-28vh) scale(.44)
    }

    55% {
        -webkit-transform: translateX(-29vw) translateY(-18vh) scale(.75);
                transform: translateX(-29vw) translateY(-18vh) scale(.75)
    }

    60% {
        -webkit-transform: translateX(27vw) translateY(-32vh) scale(.38);
                transform: translateX(27vw) translateY(-32vh) scale(.38)
    }

    65% {
        -webkit-transform: translateX(-22vw) translateY(2vh) scale(.79);
                transform: translateX(-22vw) translateY(2vh) scale(.79)
    }

    70% {
        -webkit-transform: translateX(-17vw) translateY(7vh) scale(.44);
                transform: translateX(-17vw) translateY(7vh) scale(.44)
    }

    75% {
        -webkit-transform: translateX(13vw) translateY(-45vh) scale(.53);
                transform: translateX(13vw) translateY(-45vh) scale(.53)
    }

    80% {
        -webkit-transform: translateX(32vw) translateY(8vh) scale(.9);
                transform: translateX(32vw) translateY(8vh) scale(.9)
    }

    85% {
        -webkit-transform: translateX(49vw) translateY(-26vh) scale(.45);
                transform: translateX(49vw) translateY(-26vh) scale(.45)
    }

    90% {
        -webkit-transform: translateX(10vw) translateY(20vh) scale(.88);
                transform: translateX(10vw) translateY(20vh) scale(.88)
    }

    95% {
        -webkit-transform: translateX(30vw) translateY(-9vh) scale(.51);
                transform: translateX(30vw) translateY(-9vh) scale(.51)
    }

    100% {
        -webkit-transform: translateX(-8vw) translateY(-28vh) scale(.26);
                transform: translateX(-8vw) translateY(-28vh) scale(.26)
    }
}

@keyframes fireflies_move9__3BUGz {
    0% {
        -webkit-transform: translateX(36vw) translateY(-37vh) scale(.6);
                transform: translateX(36vw) translateY(-37vh) scale(.6)
    }

    5% {
        -webkit-transform: translateX(44vw) translateY(47vh) scale(.42);
                transform: translateX(44vw) translateY(47vh) scale(.42)
    }

    10% {
        -webkit-transform: translateX(6vw) translateY(12vh) scale(.26);
                transform: translateX(6vw) translateY(12vh) scale(.26)
    }

    15% {
        -webkit-transform: translateX(-29vw) translateY(-16vh) scale(.73);
                transform: translateX(-29vw) translateY(-16vh) scale(.73)
    }

    20% {
        -webkit-transform: translateX(-4vw) translateY(11vh) scale(.6);
                transform: translateX(-4vw) translateY(11vh) scale(.6)
    }

    25% {
        -webkit-transform: translateX(33vw) translateY(48vh) scale(.26);
                transform: translateX(33vw) translateY(48vh) scale(.26)
    }

    30% {
        -webkit-transform: translateX(-44vw) translateY(15vh) scale(.84);
                transform: translateX(-44vw) translateY(15vh) scale(.84)
    }

    35% {
        -webkit-transform: translateX(5vw) translateY(-44vh) scale(.84);
                transform: translateX(5vw) translateY(-44vh) scale(.84)
    }

    40% {
        -webkit-transform: translateX(16vw) translateY(-18vh) scale(.79);
                transform: translateX(16vw) translateY(-18vh) scale(.79)
    }

    45% {
        -webkit-transform: translateX(-19vw) translateY(3vh) scale(.78);
                transform: translateX(-19vw) translateY(3vh) scale(.78)
    }

    50% {
        -webkit-transform: translateX(-26vw) translateY(-28vh) scale(.44);
                transform: translateX(-26vw) translateY(-28vh) scale(.44)
    }

    55% {
        -webkit-transform: translateX(-29vw) translateY(-18vh) scale(.75);
                transform: translateX(-29vw) translateY(-18vh) scale(.75)
    }

    60% {
        -webkit-transform: translateX(27vw) translateY(-32vh) scale(.38);
                transform: translateX(27vw) translateY(-32vh) scale(.38)
    }

    65% {
        -webkit-transform: translateX(-22vw) translateY(2vh) scale(.79);
                transform: translateX(-22vw) translateY(2vh) scale(.79)
    }

    70% {
        -webkit-transform: translateX(-17vw) translateY(7vh) scale(.44);
                transform: translateX(-17vw) translateY(7vh) scale(.44)
    }

    75% {
        -webkit-transform: translateX(13vw) translateY(-45vh) scale(.53);
                transform: translateX(13vw) translateY(-45vh) scale(.53)
    }

    80% {
        -webkit-transform: translateX(32vw) translateY(8vh) scale(.9);
                transform: translateX(32vw) translateY(8vh) scale(.9)
    }

    85% {
        -webkit-transform: translateX(49vw) translateY(-26vh) scale(.45);
                transform: translateX(49vw) translateY(-26vh) scale(.45)
    }

    90% {
        -webkit-transform: translateX(10vw) translateY(20vh) scale(.88);
                transform: translateX(10vw) translateY(20vh) scale(.88)
    }

    95% {
        -webkit-transform: translateX(30vw) translateY(-9vh) scale(.51);
                transform: translateX(30vw) translateY(-9vh) scale(.51)
    }

    100% {
        -webkit-transform: translateX(-8vw) translateY(-28vh) scale(.26);
                transform: translateX(-8vw) translateY(-28vh) scale(.26)
    }
}

.fireflies_firefly__1W6BJ:nth-child(10) {
    -webkit-animation-name: fireflies_move10__CIpJl;
            animation-name: fireflies_move10__CIpJl
}

.fireflies_firefly__1W6BJ:nth-child(10)::before {
    -webkit-animation-duration: 16s;
            animation-duration: 16s
}

.fireflies_firefly__1W6BJ:nth-child(10)::after {
    -webkit-animation-duration: 16s, 8894ms;
            animation-duration: 16s, 8894ms;
    -webkit-animation-delay: 0s, 7993ms;
            animation-delay: 0s, 7993ms
}

@-webkit-keyframes fireflies_move10__CIpJl {
    0% {
        -webkit-transform: translateX(-46vw) translateY(0) scale(.76);
                transform: translateX(-46vw) translateY(0) scale(.76)
    }

    4% {
        -webkit-transform: translateX(-5vw) translateY(10vh) scale(.63);
                transform: translateX(-5vw) translateY(10vh) scale(.63)
    }

    8% {
        -webkit-transform: translateX(-41vw) translateY(-35vh) scale(.84);
                transform: translateX(-41vw) translateY(-35vh) scale(.84)
    }

    12% {
        -webkit-transform: translateX(50vw) translateY(-43vh) scale(.76);
                transform: translateX(50vw) translateY(-43vh) scale(.76)
    }

    16% {
        -webkit-transform: translateX(-5vw) translateY(-15vh) scale(.54);
                transform: translateX(-5vw) translateY(-15vh) scale(.54)
    }

    20% {
        -webkit-transform: translateX(-48vw) translateY(-31vh) scale(.53);
                transform: translateX(-48vw) translateY(-31vh) scale(.53)
    }

    24% {
        -webkit-transform: translateX(-29vw) translateY(-2vh) scale(.39);
                transform: translateX(-29vw) translateY(-2vh) scale(.39)
    }

    28% {
        -webkit-transform: translateX(-7vw) translateY(28vh) scale(.38);
                transform: translateX(-7vw) translateY(28vh) scale(.38)
    }

    32% {
        -webkit-transform: translateX(43vw) translateY(-42vh) scale(.39);
                transform: translateX(43vw) translateY(-42vh) scale(.39)
    }

    36% {
        -webkit-transform: translateX(-6vw) translateY(24vh) scale(.98);
                transform: translateX(-6vw) translateY(24vh) scale(.98)
    }

    40% {
        -webkit-transform: translateX(44vw) translateY(46vh) scale(.99);
                transform: translateX(44vw) translateY(46vh) scale(.99)
    }

    44% {
        -webkit-transform: translateX(11vw) translateY(29vh) scale(.78);
                transform: translateX(11vw) translateY(29vh) scale(.78)
    }

    48% {
        -webkit-transform: translateX(5vw) translateY(-11vh) scale(.62);
                transform: translateX(5vw) translateY(-11vh) scale(.62)
    }

    52% {
        -webkit-transform: translateX(27vw) translateY(-40vh) scale(.48);
                transform: translateX(27vw) translateY(-40vh) scale(.48)
    }

    56% {
        -webkit-transform: translateX(-7vw) translateY(-10vh) scale(.8);
                transform: translateX(-7vw) translateY(-10vh) scale(.8)
    }

    60% {
        -webkit-transform: translateX(-27vw) translateY(19vh) scale(.79);
                transform: translateX(-27vw) translateY(19vh) scale(.79)
    }

    64% {
        -webkit-transform: translateX(-44vw) translateY(18vh) scale(.89);
                transform: translateX(-44vw) translateY(18vh) scale(.89)
    }

    68% {
        -webkit-transform: translateX(-10vw) translateY(45vh) scale(.29);
                transform: translateX(-10vw) translateY(45vh) scale(.29)
    }

    72% {
        -webkit-transform: translateX(-26vw) translateY(16vh) scale(.74);
                transform: translateX(-26vw) translateY(16vh) scale(.74)
    }

    76% {
        -webkit-transform: translateX(-22vw) translateY(-29vh) scale(.94);
                transform: translateX(-22vw) translateY(-29vh) scale(.94)
    }

    80% {
        -webkit-transform: translateX(-43vw) translateY(-18vh) scale(.71);
                transform: translateX(-43vw) translateY(-18vh) scale(.71)
    }

    84% {
        -webkit-transform: translateX(-26vw) translateY(-27vh) scale(.53);
                transform: translateX(-26vw) translateY(-27vh) scale(.53)
    }

    88% {
        -webkit-transform: translateX(14vw) translateY(45vh) scale(.79);
                transform: translateX(14vw) translateY(45vh) scale(.79)
    }

    92% {
        -webkit-transform: translateX(4vw) translateY(39vh) scale(.92);
                transform: translateX(4vw) translateY(39vh) scale(.92)
    }

    96% {
        -webkit-transform: translateX(-8vw) translateY(-18vh) scale(.92);
                transform: translateX(-8vw) translateY(-18vh) scale(.92)
    }

    100% {
        -webkit-transform: translateX(10vw) translateY(38vh) scale(.76);
                transform: translateX(10vw) translateY(38vh) scale(.76)
    }
}

@keyframes fireflies_move10__CIpJl {
    0% {
        -webkit-transform: translateX(-46vw) translateY(0) scale(.76);
                transform: translateX(-46vw) translateY(0) scale(.76)
    }

    4% {
        -webkit-transform: translateX(-5vw) translateY(10vh) scale(.63);
                transform: translateX(-5vw) translateY(10vh) scale(.63)
    }

    8% {
        -webkit-transform: translateX(-41vw) translateY(-35vh) scale(.84);
                transform: translateX(-41vw) translateY(-35vh) scale(.84)
    }

    12% {
        -webkit-transform: translateX(50vw) translateY(-43vh) scale(.76);
                transform: translateX(50vw) translateY(-43vh) scale(.76)
    }

    16% {
        -webkit-transform: translateX(-5vw) translateY(-15vh) scale(.54);
                transform: translateX(-5vw) translateY(-15vh) scale(.54)
    }

    20% {
        -webkit-transform: translateX(-48vw) translateY(-31vh) scale(.53);
                transform: translateX(-48vw) translateY(-31vh) scale(.53)
    }

    24% {
        -webkit-transform: translateX(-29vw) translateY(-2vh) scale(.39);
                transform: translateX(-29vw) translateY(-2vh) scale(.39)
    }

    28% {
        -webkit-transform: translateX(-7vw) translateY(28vh) scale(.38);
                transform: translateX(-7vw) translateY(28vh) scale(.38)
    }

    32% {
        -webkit-transform: translateX(43vw) translateY(-42vh) scale(.39);
                transform: translateX(43vw) translateY(-42vh) scale(.39)
    }

    36% {
        -webkit-transform: translateX(-6vw) translateY(24vh) scale(.98);
                transform: translateX(-6vw) translateY(24vh) scale(.98)
    }

    40% {
        -webkit-transform: translateX(44vw) translateY(46vh) scale(.99);
                transform: translateX(44vw) translateY(46vh) scale(.99)
    }

    44% {
        -webkit-transform: translateX(11vw) translateY(29vh) scale(.78);
                transform: translateX(11vw) translateY(29vh) scale(.78)
    }

    48% {
        -webkit-transform: translateX(5vw) translateY(-11vh) scale(.62);
                transform: translateX(5vw) translateY(-11vh) scale(.62)
    }

    52% {
        -webkit-transform: translateX(27vw) translateY(-40vh) scale(.48);
                transform: translateX(27vw) translateY(-40vh) scale(.48)
    }

    56% {
        -webkit-transform: translateX(-7vw) translateY(-10vh) scale(.8);
                transform: translateX(-7vw) translateY(-10vh) scale(.8)
    }

    60% {
        -webkit-transform: translateX(-27vw) translateY(19vh) scale(.79);
                transform: translateX(-27vw) translateY(19vh) scale(.79)
    }

    64% {
        -webkit-transform: translateX(-44vw) translateY(18vh) scale(.89);
                transform: translateX(-44vw) translateY(18vh) scale(.89)
    }

    68% {
        -webkit-transform: translateX(-10vw) translateY(45vh) scale(.29);
                transform: translateX(-10vw) translateY(45vh) scale(.29)
    }

    72% {
        -webkit-transform: translateX(-26vw) translateY(16vh) scale(.74);
                transform: translateX(-26vw) translateY(16vh) scale(.74)
    }

    76% {
        -webkit-transform: translateX(-22vw) translateY(-29vh) scale(.94);
                transform: translateX(-22vw) translateY(-29vh) scale(.94)
    }

    80% {
        -webkit-transform: translateX(-43vw) translateY(-18vh) scale(.71);
                transform: translateX(-43vw) translateY(-18vh) scale(.71)
    }

    84% {
        -webkit-transform: translateX(-26vw) translateY(-27vh) scale(.53);
                transform: translateX(-26vw) translateY(-27vh) scale(.53)
    }

    88% {
        -webkit-transform: translateX(14vw) translateY(45vh) scale(.79);
                transform: translateX(14vw) translateY(45vh) scale(.79)
    }

    92% {
        -webkit-transform: translateX(4vw) translateY(39vh) scale(.92);
                transform: translateX(4vw) translateY(39vh) scale(.92)
    }

    96% {
        -webkit-transform: translateX(-8vw) translateY(-18vh) scale(.92);
                transform: translateX(-8vw) translateY(-18vh) scale(.92)
    }

    100% {
        -webkit-transform: translateX(10vw) translateY(38vh) scale(.76);
                transform: translateX(10vw) translateY(38vh) scale(.76)
    }
}

.fireflies_firefly__1W6BJ:nth-child(11) {
    -webkit-animation-name: fireflies_move11__1jUCt;
            animation-name: fireflies_move11__1jUCt
}

.fireflies_firefly__1W6BJ:nth-child(11)::before {
    -webkit-animation-duration: 11s;
            animation-duration: 11s
}

.fireflies_firefly__1W6BJ:nth-child(11)::after {
    -webkit-animation-duration: 11s, 10243ms;
            animation-duration: 11s, 10243ms;
    -webkit-animation-delay: 0s, 1933ms;
            animation-delay: 0s, 1933ms
}

@-webkit-keyframes fireflies_move11__1jUCt {
    0% {
        -webkit-transform: translateX(40vw) translateY(0) scale(.93);
                transform: translateX(40vw) translateY(0) scale(.93)
    }

    4% {
        -webkit-transform: translateX(-37vw) translateY(12vh) scale(.83);
                transform: translateX(-37vw) translateY(12vh) scale(.83)
    }

    8% {
        -webkit-transform: translateX(27vw) translateY(-31vh) scale(.39);
                transform: translateX(27vw) translateY(-31vh) scale(.39)
    }

    12% {
        -webkit-transform: translateX(27vw) translateY(37vh) scale(.81);
                transform: translateX(27vw) translateY(37vh) scale(.81)
    }

    16% {
        -webkit-transform: translateX(14vw) translateY(-37vh) scale(.57);
                transform: translateX(14vw) translateY(-37vh) scale(.57)
    }

    20% {
        -webkit-transform: translateX(9vw) translateY(-4vh) scale(.41);
                transform: translateX(9vw) translateY(-4vh) scale(.41)
    }

    24% {
        -webkit-transform: translateX(-16vw) translateY(24vh) scale(.68);
                transform: translateX(-16vw) translateY(24vh) scale(.68)
    }

    28% {
        -webkit-transform: translateX(32vw) translateY(-22vh) scale(.94);
                transform: translateX(32vw) translateY(-22vh) scale(.94)
    }

    32% {
        -webkit-transform: translateX(33vw) translateY(3vh) scale(.72);
                transform: translateX(33vw) translateY(3vh) scale(.72)
    }

    36% {
        -webkit-transform: translateX(-2vw) translateY(27vh) scale(.29);
                transform: translateX(-2vw) translateY(27vh) scale(.29)
    }

    40% {
        -webkit-transform: translateX(26vw) translateY(24vh) scale(.33);
                transform: translateX(26vw) translateY(24vh) scale(.33)
    }

    44% {
        -webkit-transform: translateX(-45vw) translateY(-46vh) scale(.56);
                transform: translateX(-45vw) translateY(-46vh) scale(.56)
    }

    48% {
        -webkit-transform: translateX(-43vw) translateY(33vh) scale(.87);
                transform: translateX(-43vw) translateY(33vh) scale(.87)
    }

    52% {
        -webkit-transform: translateX(-36vw) translateY(36vh) scale(.83);
                transform: translateX(-36vw) translateY(36vh) scale(.83)
    }

    56% {
        -webkit-transform: translateX(-2vw) translateY(38vh) scale(.94);
                transform: translateX(-2vw) translateY(38vh) scale(.94)
    }

    60% {
        -webkit-transform: translateX(-27vw) translateY(4vh) scale(.97);
                transform: translateX(-27vw) translateY(4vh) scale(.97)
    }

    64% {
        -webkit-transform: translateX(-49vw) translateY(-48vh) scale(.57);
                transform: translateX(-49vw) translateY(-48vh) scale(.57)
    }

    68% {
        -webkit-transform: translateX(12vw) translateY(14vh) scale(.88);
                transform: translateX(12vw) translateY(14vh) scale(.88)
    }

    72% {
        -webkit-transform: translateX(-41vw) translateY(26vh) scale(.84);
                transform: translateX(-41vw) translateY(26vh) scale(.84)
    }

    76% {
        -webkit-transform: translateX(14vw) translateY(13vh) scale(.86);
                transform: translateX(14vw) translateY(13vh) scale(.86)
    }

    80% {
        -webkit-transform: translateX(4vw) translateY(22vh) scale(.39);
                transform: translateX(4vw) translateY(22vh) scale(.39)
    }

    84% {
        -webkit-transform: translateX(-37vw) translateY(-35vh) scale(.69);
                transform: translateX(-37vw) translateY(-35vh) scale(.69)
    }

    88% {
        -webkit-transform: translateX(36vw) translateY(24vh) scale(.78);
                transform: translateX(36vw) translateY(24vh) scale(.78)
    }

    92% {
        -webkit-transform: translateX(-34vw) translateY(-19vh) scale(.96);
                transform: translateX(-34vw) translateY(-19vh) scale(.96)
    }

    96% {
        -webkit-transform: translateX(-22vw) translateY(0) scale(.96);
                transform: translateX(-22vw) translateY(0) scale(.96)
    }

    100% {
        -webkit-transform: translateX(22vw) translateY(-33vh) scale(.5);
                transform: translateX(22vw) translateY(-33vh) scale(.5)
    }
}

@keyframes fireflies_move11__1jUCt {
    0% {
        -webkit-transform: translateX(40vw) translateY(0) scale(.93);
                transform: translateX(40vw) translateY(0) scale(.93)
    }

    4% {
        -webkit-transform: translateX(-37vw) translateY(12vh) scale(.83);
                transform: translateX(-37vw) translateY(12vh) scale(.83)
    }

    8% {
        -webkit-transform: translateX(27vw) translateY(-31vh) scale(.39);
                transform: translateX(27vw) translateY(-31vh) scale(.39)
    }

    12% {
        -webkit-transform: translateX(27vw) translateY(37vh) scale(.81);
                transform: translateX(27vw) translateY(37vh) scale(.81)
    }

    16% {
        -webkit-transform: translateX(14vw) translateY(-37vh) scale(.57);
                transform: translateX(14vw) translateY(-37vh) scale(.57)
    }

    20% {
        -webkit-transform: translateX(9vw) translateY(-4vh) scale(.41);
                transform: translateX(9vw) translateY(-4vh) scale(.41)
    }

    24% {
        -webkit-transform: translateX(-16vw) translateY(24vh) scale(.68);
                transform: translateX(-16vw) translateY(24vh) scale(.68)
    }

    28% {
        -webkit-transform: translateX(32vw) translateY(-22vh) scale(.94);
                transform: translateX(32vw) translateY(-22vh) scale(.94)
    }

    32% {
        -webkit-transform: translateX(33vw) translateY(3vh) scale(.72);
                transform: translateX(33vw) translateY(3vh) scale(.72)
    }

    36% {
        -webkit-transform: translateX(-2vw) translateY(27vh) scale(.29);
                transform: translateX(-2vw) translateY(27vh) scale(.29)
    }

    40% {
        -webkit-transform: translateX(26vw) translateY(24vh) scale(.33);
                transform: translateX(26vw) translateY(24vh) scale(.33)
    }

    44% {
        -webkit-transform: translateX(-45vw) translateY(-46vh) scale(.56);
                transform: translateX(-45vw) translateY(-46vh) scale(.56)
    }

    48% {
        -webkit-transform: translateX(-43vw) translateY(33vh) scale(.87);
                transform: translateX(-43vw) translateY(33vh) scale(.87)
    }

    52% {
        -webkit-transform: translateX(-36vw) translateY(36vh) scale(.83);
                transform: translateX(-36vw) translateY(36vh) scale(.83)
    }

    56% {
        -webkit-transform: translateX(-2vw) translateY(38vh) scale(.94);
                transform: translateX(-2vw) translateY(38vh) scale(.94)
    }

    60% {
        -webkit-transform: translateX(-27vw) translateY(4vh) scale(.97);
                transform: translateX(-27vw) translateY(4vh) scale(.97)
    }

    64% {
        -webkit-transform: translateX(-49vw) translateY(-48vh) scale(.57);
                transform: translateX(-49vw) translateY(-48vh) scale(.57)
    }

    68% {
        -webkit-transform: translateX(12vw) translateY(14vh) scale(.88);
                transform: translateX(12vw) translateY(14vh) scale(.88)
    }

    72% {
        -webkit-transform: translateX(-41vw) translateY(26vh) scale(.84);
                transform: translateX(-41vw) translateY(26vh) scale(.84)
    }

    76% {
        -webkit-transform: translateX(14vw) translateY(13vh) scale(.86);
                transform: translateX(14vw) translateY(13vh) scale(.86)
    }

    80% {
        -webkit-transform: translateX(4vw) translateY(22vh) scale(.39);
                transform: translateX(4vw) translateY(22vh) scale(.39)
    }

    84% {
        -webkit-transform: translateX(-37vw) translateY(-35vh) scale(.69);
                transform: translateX(-37vw) translateY(-35vh) scale(.69)
    }

    88% {
        -webkit-transform: translateX(36vw) translateY(24vh) scale(.78);
                transform: translateX(36vw) translateY(24vh) scale(.78)
    }

    92% {
        -webkit-transform: translateX(-34vw) translateY(-19vh) scale(.96);
                transform: translateX(-34vw) translateY(-19vh) scale(.96)
    }

    96% {
        -webkit-transform: translateX(-22vw) translateY(0) scale(.96);
                transform: translateX(-22vw) translateY(0) scale(.96)
    }

    100% {
        -webkit-transform: translateX(22vw) translateY(-33vh) scale(.5);
                transform: translateX(22vw) translateY(-33vh) scale(.5)
    }
}

.fireflies_firefly__1W6BJ:nth-child(12) {
    -webkit-animation-name: fireflies_move12__Ww-Nx;
            animation-name: fireflies_move12__Ww-Nx
}

.fireflies_firefly__1W6BJ:nth-child(12)::before {
    -webkit-animation-duration: 13s;
            animation-duration: 13s
}

.fireflies_firefly__1W6BJ:nth-child(12)::after {
    -webkit-animation-duration: 13s, 6584ms;
            animation-duration: 13s, 6584ms;
    -webkit-animation-delay: 0s, 2535ms;
            animation-delay: 0s, 2535ms
}

@-webkit-keyframes fireflies_move12__Ww-Nx {
    0% {
        -webkit-transform: translateX(-39vw) translateY(6vh) scale(.7);
                transform: translateX(-39vw) translateY(6vh) scale(.7)
    }

    5.2631578947% {
        -webkit-transform: translateX(41vw) translateY(-45vh) scale(.82);
                transform: translateX(41vw) translateY(-45vh) scale(.82)
    }

    10.5263157895% {
        -webkit-transform: translateX(-7vw) translateY(20vh) scale(.71);
                transform: translateX(-7vw) translateY(20vh) scale(.71)
    }

    15.7894736842% {
        -webkit-transform: translateX(-43vw) translateY(21vh) scale(.34);
                transform: translateX(-43vw) translateY(21vh) scale(.34)
    }

    21.0526315789% {
        -webkit-transform: translateX(-21vw) translateY(33vh) scale(.48);
                transform: translateX(-21vw) translateY(33vh) scale(.48)
    }

    26.3157894737% {
        -webkit-transform: translateX(30vw) translateY(-16vh) scale(.43);
                transform: translateX(30vw) translateY(-16vh) scale(.43)
    }

    31.5789473684% {
        -webkit-transform: translateX(-5vw) translateY(6vh) scale(.26);
                transform: translateX(-5vw) translateY(6vh) scale(.26)
    }

    36.8421052632% {
        -webkit-transform: translateX(-8vw) translateY(-23vh) scale(.39);
                transform: translateX(-8vw) translateY(-23vh) scale(.39)
    }

    42.1052631579% {
        -webkit-transform: translateX(-37vw) translateY(21vh) scale(.61);
                transform: translateX(-37vw) translateY(21vh) scale(.61)
    }

    47.3684210526% {
        -webkit-transform: translateX(-46vw) translateY(-2vh) scale(.41);
                transform: translateX(-46vw) translateY(-2vh) scale(.41)
    }

    52.6315789474% {
        -webkit-transform: translateX(38vw) translateY(26vh) scale(.65);
                transform: translateX(38vw) translateY(26vh) scale(.65)
    }

    57.8947368421% {
        -webkit-transform: translateX(-20vw) translateY(4vh) scale(.44);
                transform: translateX(-20vw) translateY(4vh) scale(.44)
    }

    63.1578947368% {
        -webkit-transform: translateX(0) translateY(11vh) scale(.94);
                transform: translateX(0) translateY(11vh) scale(.94)
    }

    68.4210526316% {
        -webkit-transform: translateX(39vw) translateY(-17vh) scale(.88);
                transform: translateX(39vw) translateY(-17vh) scale(.88)
    }

    73.6842105263% {
        -webkit-transform: translateX(4vw) translateY(40vh) scale(.94);
                transform: translateX(4vw) translateY(40vh) scale(.94)
    }

    78.9473684211% {
        -webkit-transform: translateX(-17vw) translateY(-36vh) scale(.32);
                transform: translateX(-17vw) translateY(-36vh) scale(.32)
    }

    84.2105263158% {
        -webkit-transform: translateX(-48vw) translateY(-19vh) scale(.87);
                transform: translateX(-48vw) translateY(-19vh) scale(.87)
    }

    89.4736842105% {
        -webkit-transform: translateX(-43vw) translateY(-18vh) scale(.66);
                transform: translateX(-43vw) translateY(-18vh) scale(.66)
    }

    94.7368421053% {
        -webkit-transform: translateX(-20vw) translateY(-20vh) scale(.58);
                transform: translateX(-20vw) translateY(-20vh) scale(.58)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(43vh) scale(.64);
                transform: translateX(-26vw) translateY(43vh) scale(.64)
    }
}

@keyframes fireflies_move12__Ww-Nx {
    0% {
        -webkit-transform: translateX(-39vw) translateY(6vh) scale(.7);
                transform: translateX(-39vw) translateY(6vh) scale(.7)
    }

    5.2631578947% {
        -webkit-transform: translateX(41vw) translateY(-45vh) scale(.82);
                transform: translateX(41vw) translateY(-45vh) scale(.82)
    }

    10.5263157895% {
        -webkit-transform: translateX(-7vw) translateY(20vh) scale(.71);
                transform: translateX(-7vw) translateY(20vh) scale(.71)
    }

    15.7894736842% {
        -webkit-transform: translateX(-43vw) translateY(21vh) scale(.34);
                transform: translateX(-43vw) translateY(21vh) scale(.34)
    }

    21.0526315789% {
        -webkit-transform: translateX(-21vw) translateY(33vh) scale(.48);
                transform: translateX(-21vw) translateY(33vh) scale(.48)
    }

    26.3157894737% {
        -webkit-transform: translateX(30vw) translateY(-16vh) scale(.43);
                transform: translateX(30vw) translateY(-16vh) scale(.43)
    }

    31.5789473684% {
        -webkit-transform: translateX(-5vw) translateY(6vh) scale(.26);
                transform: translateX(-5vw) translateY(6vh) scale(.26)
    }

    36.8421052632% {
        -webkit-transform: translateX(-8vw) translateY(-23vh) scale(.39);
                transform: translateX(-8vw) translateY(-23vh) scale(.39)
    }

    42.1052631579% {
        -webkit-transform: translateX(-37vw) translateY(21vh) scale(.61);
                transform: translateX(-37vw) translateY(21vh) scale(.61)
    }

    47.3684210526% {
        -webkit-transform: translateX(-46vw) translateY(-2vh) scale(.41);
                transform: translateX(-46vw) translateY(-2vh) scale(.41)
    }

    52.6315789474% {
        -webkit-transform: translateX(38vw) translateY(26vh) scale(.65);
                transform: translateX(38vw) translateY(26vh) scale(.65)
    }

    57.8947368421% {
        -webkit-transform: translateX(-20vw) translateY(4vh) scale(.44);
                transform: translateX(-20vw) translateY(4vh) scale(.44)
    }

    63.1578947368% {
        -webkit-transform: translateX(0) translateY(11vh) scale(.94);
                transform: translateX(0) translateY(11vh) scale(.94)
    }

    68.4210526316% {
        -webkit-transform: translateX(39vw) translateY(-17vh) scale(.88);
                transform: translateX(39vw) translateY(-17vh) scale(.88)
    }

    73.6842105263% {
        -webkit-transform: translateX(4vw) translateY(40vh) scale(.94);
                transform: translateX(4vw) translateY(40vh) scale(.94)
    }

    78.9473684211% {
        -webkit-transform: translateX(-17vw) translateY(-36vh) scale(.32);
                transform: translateX(-17vw) translateY(-36vh) scale(.32)
    }

    84.2105263158% {
        -webkit-transform: translateX(-48vw) translateY(-19vh) scale(.87);
                transform: translateX(-48vw) translateY(-19vh) scale(.87)
    }

    89.4736842105% {
        -webkit-transform: translateX(-43vw) translateY(-18vh) scale(.66);
                transform: translateX(-43vw) translateY(-18vh) scale(.66)
    }

    94.7368421053% {
        -webkit-transform: translateX(-20vw) translateY(-20vh) scale(.58);
                transform: translateX(-20vw) translateY(-20vh) scale(.58)
    }

    100% {
        -webkit-transform: translateX(-26vw) translateY(43vh) scale(.64);
                transform: translateX(-26vw) translateY(43vh) scale(.64)
    }
}

.fireflies_firefly__1W6BJ:nth-child(13) {
    -webkit-animation-name: fireflies_move13__3NVDu;
            animation-name: fireflies_move13__3NVDu
}

.fireflies_firefly__1W6BJ:nth-child(13)::before {
    -webkit-animation-duration: 10s;
            animation-duration: 10s
}

.fireflies_firefly__1W6BJ:nth-child(13)::after {
    -webkit-animation-duration: 10s, 10709ms;
            animation-duration: 10s, 10709ms;
    -webkit-animation-delay: 0s, 6.96s;
            animation-delay: 0s, 6.96s
}

@-webkit-keyframes fireflies_move13__3NVDu {
    0% {
        -webkit-transform: translateX(-10vw) translateY(-39vh) scale(.38);
                transform: translateX(-10vw) translateY(-39vh) scale(.38)
    }

    3.8461538462% {
        -webkit-transform: translateX(-19vw) translateY(-35vh) scale(.68);
                transform: translateX(-19vw) translateY(-35vh) scale(.68)
    }

    7.6923076923% {
        -webkit-transform: translateX(27vw) translateY(-33vh) scale(.9);
                transform: translateX(27vw) translateY(-33vh) scale(.9)
    }

    11.5384615385% {
        -webkit-transform: translateX(36vw) translateY(9vh) scale(.75);
                transform: translateX(36vw) translateY(9vh) scale(.75)
    }

    15.3846153846% {
        -webkit-transform: translateX(-48vw) translateY(-23vh) scale(.44);
                transform: translateX(-48vw) translateY(-23vh) scale(.44)
    }

    19.2307692308% {
        -webkit-transform: translateX(-39vw) translateY(-35vh) scale(.4);
                transform: translateX(-39vw) translateY(-35vh) scale(.4)
    }

    23.0769230769% {
        -webkit-transform: translateX(-20vw) translateY(-32vh) scale(.92);
                transform: translateX(-20vw) translateY(-32vh) scale(.92)
    }

    26.9230769231% {
        -webkit-transform: translateX(49vw) translateY(45vh) scale(.85);
                transform: translateX(49vw) translateY(45vh) scale(.85)
    }

    30.7692307692% {
        -webkit-transform: translateX(-15vw) translateY(-41vh) scale(.38);
                transform: translateX(-15vw) translateY(-41vh) scale(.38)
    }

    34.6153846154% {
        -webkit-transform: translateX(46vw) translateY(12vh) scale(.33);
                transform: translateX(46vw) translateY(12vh) scale(.33)
    }

    38.4615384615% {
        -webkit-transform: translateX(22vw) translateY(-8vh) scale(.55);
                transform: translateX(22vw) translateY(-8vh) scale(.55)
    }

    42.3076923077% {
        -webkit-transform: translateX(42vw) translateY(7vh) scale(.38);
                transform: translateX(42vw) translateY(7vh) scale(.38)
    }

    46.1538461538% {
        -webkit-transform: translateX(44vw) translateY(44vh) scale(.86);
                transform: translateX(44vw) translateY(44vh) scale(.86)
    }

    50% {
        -webkit-transform: translateX(44vw) translateY(-23vh) scale(.32);
                transform: translateX(44vw) translateY(-23vh) scale(.32)
    }

    53.8461538462% {
        -webkit-transform: translateX(12vw) translateY(48vh) scale(.28);
                transform: translateX(12vw) translateY(48vh) scale(.28)
    }

    57.6923076923% {
        -webkit-transform: translateX(-47vw) translateY(23vh) scale(.71);
                transform: translateX(-47vw) translateY(23vh) scale(.71)
    }

    61.5384615385% {
        -webkit-transform: translateX(24vw) translateY(-43vh) scale(.65);
                transform: translateX(24vw) translateY(-43vh) scale(.65)
    }

    65.3846153846% {
        -webkit-transform: translateX(11vw) translateY(-32vh) scale(.91);
                transform: translateX(11vw) translateY(-32vh) scale(.91)
    }

    69.2307692308% {
        -webkit-transform: translateX(3vw) translateY(-3vh) scale(.35);
                transform: translateX(3vw) translateY(-3vh) scale(.35)
    }

    73.0769230769% {
        -webkit-transform: translateX(-24vw) translateY(14vh) scale(.51);
                transform: translateX(-24vw) translateY(14vh) scale(.51)
    }

    76.9230769231% {
        -webkit-transform: translateX(23vw) translateY(23vh) scale(.29);
                transform: translateX(23vw) translateY(23vh) scale(.29)
    }

    80.7692307692% {
        -webkit-transform: translateX(29vw) translateY(-5vh) scale(.51);
                transform: translateX(29vw) translateY(-5vh) scale(.51)
    }

    84.6153846154% {
        -webkit-transform: translateX(9vw) translateY(-15vh) scale(.58);
                transform: translateX(9vw) translateY(-15vh) scale(.58)
    }

    88.4615384615% {
        -webkit-transform: translateX(-44vw) translateY(31vh) scale(.29);
                transform: translateX(-44vw) translateY(31vh) scale(.29)
    }

    92.3076923077% {
        -webkit-transform: translateX(-18vw) translateY(46vh) scale(.83);
                transform: translateX(-18vw) translateY(46vh) scale(.83)
    }

    96.1538461538% {
        -webkit-transform: translateX(-4vw) translateY(32vh) scale(.97);
                transform: translateX(-4vw) translateY(32vh) scale(.97)
    }

    100% {
        -webkit-transform: translateX(6vw) translateY(-32vh) scale(.86);
                transform: translateX(6vw) translateY(-32vh) scale(.86)
    }
}

@keyframes fireflies_move13__3NVDu {
    0% {
        -webkit-transform: translateX(-10vw) translateY(-39vh) scale(.38);
                transform: translateX(-10vw) translateY(-39vh) scale(.38)
    }

    3.8461538462% {
        -webkit-transform: translateX(-19vw) translateY(-35vh) scale(.68);
                transform: translateX(-19vw) translateY(-35vh) scale(.68)
    }

    7.6923076923% {
        -webkit-transform: translateX(27vw) translateY(-33vh) scale(.9);
                transform: translateX(27vw) translateY(-33vh) scale(.9)
    }

    11.5384615385% {
        -webkit-transform: translateX(36vw) translateY(9vh) scale(.75);
                transform: translateX(36vw) translateY(9vh) scale(.75)
    }

    15.3846153846% {
        -webkit-transform: translateX(-48vw) translateY(-23vh) scale(.44);
                transform: translateX(-48vw) translateY(-23vh) scale(.44)
    }

    19.2307692308% {
        -webkit-transform: translateX(-39vw) translateY(-35vh) scale(.4);
                transform: translateX(-39vw) translateY(-35vh) scale(.4)
    }

    23.0769230769% {
        -webkit-transform: translateX(-20vw) translateY(-32vh) scale(.92);
                transform: translateX(-20vw) translateY(-32vh) scale(.92)
    }

    26.9230769231% {
        -webkit-transform: translateX(49vw) translateY(45vh) scale(.85);
                transform: translateX(49vw) translateY(45vh) scale(.85)
    }

    30.7692307692% {
        -webkit-transform: translateX(-15vw) translateY(-41vh) scale(.38);
                transform: translateX(-15vw) translateY(-41vh) scale(.38)
    }

    34.6153846154% {
        -webkit-transform: translateX(46vw) translateY(12vh) scale(.33);
                transform: translateX(46vw) translateY(12vh) scale(.33)
    }

    38.4615384615% {
        -webkit-transform: translateX(22vw) translateY(-8vh) scale(.55);
                transform: translateX(22vw) translateY(-8vh) scale(.55)
    }

    42.3076923077% {
        -webkit-transform: translateX(42vw) translateY(7vh) scale(.38);
                transform: translateX(42vw) translateY(7vh) scale(.38)
    }

    46.1538461538% {
        -webkit-transform: translateX(44vw) translateY(44vh) scale(.86);
                transform: translateX(44vw) translateY(44vh) scale(.86)
    }

    50% {
        -webkit-transform: translateX(44vw) translateY(-23vh) scale(.32);
                transform: translateX(44vw) translateY(-23vh) scale(.32)
    }

    53.8461538462% {
        -webkit-transform: translateX(12vw) translateY(48vh) scale(.28);
                transform: translateX(12vw) translateY(48vh) scale(.28)
    }

    57.6923076923% {
        -webkit-transform: translateX(-47vw) translateY(23vh) scale(.71);
                transform: translateX(-47vw) translateY(23vh) scale(.71)
    }

    61.5384615385% {
        -webkit-transform: translateX(24vw) translateY(-43vh) scale(.65);
                transform: translateX(24vw) translateY(-43vh) scale(.65)
    }

    65.3846153846% {
        -webkit-transform: translateX(11vw) translateY(-32vh) scale(.91);
                transform: translateX(11vw) translateY(-32vh) scale(.91)
    }

    69.2307692308% {
        -webkit-transform: translateX(3vw) translateY(-3vh) scale(.35);
                transform: translateX(3vw) translateY(-3vh) scale(.35)
    }

    73.0769230769% {
        -webkit-transform: translateX(-24vw) translateY(14vh) scale(.51);
                transform: translateX(-24vw) translateY(14vh) scale(.51)
    }

    76.9230769231% {
        -webkit-transform: translateX(23vw) translateY(23vh) scale(.29);
                transform: translateX(23vw) translateY(23vh) scale(.29)
    }

    80.7692307692% {
        -webkit-transform: translateX(29vw) translateY(-5vh) scale(.51);
                transform: translateX(29vw) translateY(-5vh) scale(.51)
    }

    84.6153846154% {
        -webkit-transform: translateX(9vw) translateY(-15vh) scale(.58);
                transform: translateX(9vw) translateY(-15vh) scale(.58)
    }

    88.4615384615% {
        -webkit-transform: translateX(-44vw) translateY(31vh) scale(.29);
                transform: translateX(-44vw) translateY(31vh) scale(.29)
    }

    92.3076923077% {
        -webkit-transform: translateX(-18vw) translateY(46vh) scale(.83);
                transform: translateX(-18vw) translateY(46vh) scale(.83)
    }

    96.1538461538% {
        -webkit-transform: translateX(-4vw) translateY(32vh) scale(.97);
                transform: translateX(-4vw) translateY(32vh) scale(.97)
    }

    100% {
        -webkit-transform: translateX(6vw) translateY(-32vh) scale(.86);
                transform: translateX(6vw) translateY(-32vh) scale(.86)
    }
}

.fireflies_firefly__1W6BJ:nth-child(14) {
    -webkit-animation-name: fireflies_move14__2IYZW;
            animation-name: fireflies_move14__2IYZW
}

.fireflies_firefly__1W6BJ:nth-child(14)::before {
    -webkit-animation-duration: 13s;
            animation-duration: 13s
}

.fireflies_firefly__1W6BJ:nth-child(14)::after {
    -webkit-animation-duration: 13s, 7581ms;
            animation-duration: 13s, 7581ms;
    -webkit-animation-delay: 0s, 1.99s;
            animation-delay: 0s, 1.99s
}

@-webkit-keyframes fireflies_move14__2IYZW {
    0% {
        -webkit-transform: translateX(38vw) translateY(18vh) scale(.27);
                transform: translateX(38vw) translateY(18vh) scale(.27)
    }

    5.8823529412% {
        -webkit-transform: translateX(-5vw) translateY(-39vh) scale(.93);
                transform: translateX(-5vw) translateY(-39vh) scale(.93)
    }

    11.7647058824% {
        -webkit-transform: translateX(-49vw) translateY(8vh) scale(.77);
                transform: translateX(-49vw) translateY(8vh) scale(.77)
    }

    17.6470588235% {
        -webkit-transform: translateX(0) translateY(42vh) scale(.36);
                transform: translateX(0) translateY(42vh) scale(.36)
    }

    23.5294117647% {
        -webkit-transform: translateX(29vw) translateY(-41vh) scale(.97);
                transform: translateX(29vw) translateY(-41vh) scale(.97)
    }

    29.4117647059% {
        -webkit-transform: translateX(-18vw) translateY(29vh) scale(.81);
                transform: translateX(-18vw) translateY(29vh) scale(.81)
    }

    35.2941176471% {
        -webkit-transform: translateX(10vw) translateY(18vh) scale(.91);
                transform: translateX(10vw) translateY(18vh) scale(.91)
    }

    41.1764705882% {
        -webkit-transform: translateX(-1vw) translateY(-39vh) scale(.97);
                transform: translateX(-1vw) translateY(-39vh) scale(.97)
    }

    47.0588235294% {
        -webkit-transform: translateX(19vw) translateY(45vh) scale(.47);
                transform: translateX(19vw) translateY(45vh) scale(.47)
    }

    52.9411764706% {
        -webkit-transform: translateX(-15vw) translateY(4vh) scale(.46);
                transform: translateX(-15vw) translateY(4vh) scale(.46)
    }

    58.8235294118% {
        -webkit-transform: translateX(-23vw) translateY(39vh) scale(.9);
                transform: translateX(-23vw) translateY(39vh) scale(.9)
    }

    64.7058823529% {
        -webkit-transform: translateX(9vw) translateY(-35vh) scale(.81);
                transform: translateX(9vw) translateY(-35vh) scale(.81)
    }

    70.5882352941% {
        -webkit-transform: translateX(36vw) translateY(-25vh) scale(.38);
                transform: translateX(36vw) translateY(-25vh) scale(.38)
    }

    76.4705882353% {
        -webkit-transform: translateX(-8vw) translateY(38vh) scale(.61);
                transform: translateX(-8vw) translateY(38vh) scale(.61)
    }

    82.3529411765% {
        -webkit-transform: translateX(-39vw) translateY(22vh) scale(.8);
                transform: translateX(-39vw) translateY(22vh) scale(.8)
    }

    88.2352941176% {
        -webkit-transform: translateX(35vw) translateY(8vh) scale(.43);
                transform: translateX(35vw) translateY(8vh) scale(.43)
    }

    94.1176470588% {
        -webkit-transform: translateX(43vw) translateY(21vh) scale(.79);
                transform: translateX(43vw) translateY(21vh) scale(.79)
    }

    100% {
        -webkit-transform: translateX(-46vw) translateY(-15vh) scale(.55);
                transform: translateX(-46vw) translateY(-15vh) scale(.55)
    }
}

@keyframes fireflies_move14__2IYZW {
    0% {
        -webkit-transform: translateX(38vw) translateY(18vh) scale(.27);
                transform: translateX(38vw) translateY(18vh) scale(.27)
    }

    5.8823529412% {
        -webkit-transform: translateX(-5vw) translateY(-39vh) scale(.93);
                transform: translateX(-5vw) translateY(-39vh) scale(.93)
    }

    11.7647058824% {
        -webkit-transform: translateX(-49vw) translateY(8vh) scale(.77);
                transform: translateX(-49vw) translateY(8vh) scale(.77)
    }

    17.6470588235% {
        -webkit-transform: translateX(0) translateY(42vh) scale(.36);
                transform: translateX(0) translateY(42vh) scale(.36)
    }

    23.5294117647% {
        -webkit-transform: translateX(29vw) translateY(-41vh) scale(.97);
                transform: translateX(29vw) translateY(-41vh) scale(.97)
    }

    29.4117647059% {
        -webkit-transform: translateX(-18vw) translateY(29vh) scale(.81);
                transform: translateX(-18vw) translateY(29vh) scale(.81)
    }

    35.2941176471% {
        -webkit-transform: translateX(10vw) translateY(18vh) scale(.91);
                transform: translateX(10vw) translateY(18vh) scale(.91)
    }

    41.1764705882% {
        -webkit-transform: translateX(-1vw) translateY(-39vh) scale(.97);
                transform: translateX(-1vw) translateY(-39vh) scale(.97)
    }

    47.0588235294% {
        -webkit-transform: translateX(19vw) translateY(45vh) scale(.47);
                transform: translateX(19vw) translateY(45vh) scale(.47)
    }

    52.9411764706% {
        -webkit-transform: translateX(-15vw) translateY(4vh) scale(.46);
                transform: translateX(-15vw) translateY(4vh) scale(.46)
    }

    58.8235294118% {
        -webkit-transform: translateX(-23vw) translateY(39vh) scale(.9);
                transform: translateX(-23vw) translateY(39vh) scale(.9)
    }

    64.7058823529% {
        -webkit-transform: translateX(9vw) translateY(-35vh) scale(.81);
                transform: translateX(9vw) translateY(-35vh) scale(.81)
    }

    70.5882352941% {
        -webkit-transform: translateX(36vw) translateY(-25vh) scale(.38);
                transform: translateX(36vw) translateY(-25vh) scale(.38)
    }

    76.4705882353% {
        -webkit-transform: translateX(-8vw) translateY(38vh) scale(.61);
                transform: translateX(-8vw) translateY(38vh) scale(.61)
    }

    82.3529411765% {
        -webkit-transform: translateX(-39vw) translateY(22vh) scale(.8);
                transform: translateX(-39vw) translateY(22vh) scale(.8)
    }

    88.2352941176% {
        -webkit-transform: translateX(35vw) translateY(8vh) scale(.43);
                transform: translateX(35vw) translateY(8vh) scale(.43)
    }

    94.1176470588% {
        -webkit-transform: translateX(43vw) translateY(21vh) scale(.79);
                transform: translateX(43vw) translateY(21vh) scale(.79)
    }

    100% {
        -webkit-transform: translateX(-46vw) translateY(-15vh) scale(.55);
                transform: translateX(-46vw) translateY(-15vh) scale(.55)
    }
}

.fireflies_firefly__1W6BJ:nth-child(15) {
    -webkit-animation-name: fireflies_move15__KTD8k;
            animation-name: fireflies_move15__KTD8k
}

.fireflies_firefly__1W6BJ:nth-child(15)::before {
    -webkit-animation-duration: 11s;
            animation-duration: 11s
}

.fireflies_firefly__1W6BJ:nth-child(15)::after {
    -webkit-animation-duration: 11s, 5177ms;
            animation-duration: 11s, 5177ms;
    -webkit-animation-delay: 0s, 6702ms;
            animation-delay: 0s, 6702ms
}

@-webkit-keyframes fireflies_move15__KTD8k {
    0% {
        -webkit-transform: translateX(-33vw) translateY(16vh) scale(.63);
                transform: translateX(-33vw) translateY(16vh) scale(.63)
    }

    4.7619047619% {
        -webkit-transform: translateX(-2vw) translateY(-32vh) scale(.75);
                transform: translateX(-2vw) translateY(-32vh) scale(.75)
    }

    9.5238095238% {
        -webkit-transform: translateX(-27vw) translateY(23vh) scale(.71);
                transform: translateX(-27vw) translateY(23vh) scale(.71)
    }

    14.2857142857% {
        -webkit-transform: translateX(41vw) translateY(36vh) scale(.84);
                transform: translateX(41vw) translateY(36vh) scale(.84)
    }

    19.0476190476% {
        -webkit-transform: translateX(32vw) translateY(14vh) scale(.46);
                transform: translateX(32vw) translateY(14vh) scale(.46)
    }

    23.8095238095% {
        -webkit-transform: translateX(-8vw) translateY(45vh) scale(.68);
                transform: translateX(-8vw) translateY(45vh) scale(.68)
    }

    28.5714285714% {
        -webkit-transform: translateX(-13vw) translateY(43vh) scale(.99);
                transform: translateX(-13vw) translateY(43vh) scale(.99)
    }

    33.3333333333% {
        -webkit-transform: translateX(-1vw) translateY(39vh) scale(.56);
                transform: translateX(-1vw) translateY(39vh) scale(.56)
    }

    38.0952380952% {
        -webkit-transform: translateX(14vw) translateY(-28vh) scale(.38);
                transform: translateX(14vw) translateY(-28vh) scale(.38)
    }

    42.8571428571% {
        -webkit-transform: translateX(16vw) translateY(0) scale(.89);
                transform: translateX(16vw) translateY(0) scale(.89)
    }

    47.619047619% {
        -webkit-transform: translateX(-47vw) translateY(-27vh) scale(.7);
                transform: translateX(-47vw) translateY(-27vh) scale(.7)
    }

    52.380952381% {
        -webkit-transform: translateX(47vw) translateY(28vh) scale(.39);
                transform: translateX(47vw) translateY(28vh) scale(.39)
    }

    57.1428571429% {
        -webkit-transform: translateX(-12vw) translateY(9vh) scale(.77);
                transform: translateX(-12vw) translateY(9vh) scale(.77)
    }

    61.9047619048% {
        -webkit-transform: translateX(-6vw) translateY(41vh) scale(.54);
                transform: translateX(-6vw) translateY(41vh) scale(.54)
    }

    66.6666666667% {
        -webkit-transform: translateX(-23vw) translateY(-35vh) scale(.71);
                transform: translateX(-23vw) translateY(-35vh) scale(.71)
    }

    71.4285714286% {
        -webkit-transform: translateX(-13vw) translateY(36vh) scale(.77);
                transform: translateX(-13vw) translateY(36vh) scale(.77)
    }

    76.1904761905% {
        -webkit-transform: translateX(17vw) translateY(31vh) scale(.93);
                transform: translateX(17vw) translateY(31vh) scale(.93)
    }

    80.9523809524% {
        -webkit-transform: translateX(33vw) translateY(49vh) scale(.84);
                transform: translateX(33vw) translateY(49vh) scale(.84)
    }

    85.7142857143% {
        -webkit-transform: translateX(34vw) translateY(44vh) scale(.86);
                transform: translateX(34vw) translateY(44vh) scale(.86)
    }

    90.4761904762% {
        -webkit-transform: translateX(-26vw) translateY(14vh) scale(.48);
                transform: translateX(-26vw) translateY(14vh) scale(.48)
    }

    95.2380952381% {
        -webkit-transform: translateX(-21vw) translateY(35vh) scale(.94);
                transform: translateX(-21vw) translateY(35vh) scale(.94)
    }

    100% {
        -webkit-transform: translateX(10vw) translateY(-46vh) scale(.7);
                transform: translateX(10vw) translateY(-46vh) scale(.7)
    }
}

@keyframes fireflies_move15__KTD8k {
    0% {
        -webkit-transform: translateX(-33vw) translateY(16vh) scale(.63);
                transform: translateX(-33vw) translateY(16vh) scale(.63)
    }

    4.7619047619% {
        -webkit-transform: translateX(-2vw) translateY(-32vh) scale(.75);
                transform: translateX(-2vw) translateY(-32vh) scale(.75)
    }

    9.5238095238% {
        -webkit-transform: translateX(-27vw) translateY(23vh) scale(.71);
                transform: translateX(-27vw) translateY(23vh) scale(.71)
    }

    14.2857142857% {
        -webkit-transform: translateX(41vw) translateY(36vh) scale(.84);
                transform: translateX(41vw) translateY(36vh) scale(.84)
    }

    19.0476190476% {
        -webkit-transform: translateX(32vw) translateY(14vh) scale(.46);
                transform: translateX(32vw) translateY(14vh) scale(.46)
    }

    23.8095238095% {
        -webkit-transform: translateX(-8vw) translateY(45vh) scale(.68);
                transform: translateX(-8vw) translateY(45vh) scale(.68)
    }

    28.5714285714% {
        -webkit-transform: translateX(-13vw) translateY(43vh) scale(.99);
                transform: translateX(-13vw) translateY(43vh) scale(.99)
    }

    33.3333333333% {
        -webkit-transform: translateX(-1vw) translateY(39vh) scale(.56);
                transform: translateX(-1vw) translateY(39vh) scale(.56)
    }

    38.0952380952% {
        -webkit-transform: translateX(14vw) translateY(-28vh) scale(.38);
                transform: translateX(14vw) translateY(-28vh) scale(.38)
    }

    42.8571428571% {
        -webkit-transform: translateX(16vw) translateY(0) scale(.89);
                transform: translateX(16vw) translateY(0) scale(.89)
    }

    47.619047619% {
        -webkit-transform: translateX(-47vw) translateY(-27vh) scale(.7);
                transform: translateX(-47vw) translateY(-27vh) scale(.7)
    }

    52.380952381% {
        -webkit-transform: translateX(47vw) translateY(28vh) scale(.39);
                transform: translateX(47vw) translateY(28vh) scale(.39)
    }

    57.1428571429% {
        -webkit-transform: translateX(-12vw) translateY(9vh) scale(.77);
                transform: translateX(-12vw) translateY(9vh) scale(.77)
    }

    61.9047619048% {
        -webkit-transform: translateX(-6vw) translateY(41vh) scale(.54);
                transform: translateX(-6vw) translateY(41vh) scale(.54)
    }

    66.6666666667% {
        -webkit-transform: translateX(-23vw) translateY(-35vh) scale(.71);
                transform: translateX(-23vw) translateY(-35vh) scale(.71)
    }

    71.4285714286% {
        -webkit-transform: translateX(-13vw) translateY(36vh) scale(.77);
                transform: translateX(-13vw) translateY(36vh) scale(.77)
    }

    76.1904761905% {
        -webkit-transform: translateX(17vw) translateY(31vh) scale(.93);
                transform: translateX(17vw) translateY(31vh) scale(.93)
    }

    80.9523809524% {
        -webkit-transform: translateX(33vw) translateY(49vh) scale(.84);
                transform: translateX(33vw) translateY(49vh) scale(.84)
    }

    85.7142857143% {
        -webkit-transform: translateX(34vw) translateY(44vh) scale(.86);
                transform: translateX(34vw) translateY(44vh) scale(.86)
    }

    90.4761904762% {
        -webkit-transform: translateX(-26vw) translateY(14vh) scale(.48);
                transform: translateX(-26vw) translateY(14vh) scale(.48)
    }

    95.2380952381% {
        -webkit-transform: translateX(-21vw) translateY(35vh) scale(.94);
                transform: translateX(-21vw) translateY(35vh) scale(.94)
    }

    100% {
        -webkit-transform: translateX(10vw) translateY(-46vh) scale(.7);
                transform: translateX(10vw) translateY(-46vh) scale(.7)
    }
}

@-webkit-keyframes fireflies_drift__1gY9T {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@keyframes fireflies_drift__1gY9T {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg)
    }
}

@-webkit-keyframes fireflies_flash__1tDgO {

    0%,
    100%,
    30% {
        opacity: 0;
        box-shadow: 0 0 0 0 #ff0
    }

    5% {
        opacity: 1;
        box-shadow: 0 0 2vw .4vw #ff0
    }
}

@keyframes fireflies_flash__1tDgO {

    0%,
    100%,
    30% {
        opacity: 0;
        box-shadow: 0 0 0 0 #ff0
    }

    5% {
        opacity: 1;
        box-shadow: 0 0 2vw .4vw #ff0
    }
}
